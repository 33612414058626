import Vue from 'vue'

/**
 * 获取合同信息中的抽屉数据格式
 * @param {*} title 当前客户名称
 * @param {*} authorityData 权限数据
 * @param {*} isHead 是否是机构负责人
 * @param {*} options 所有下拉框数组
 * @param {*} flag 当前打开的子页面
 * @param {*} butText 抽屉中的按钮 随着状态的改变而改变
 */

export function contractInfoDrawerData(title, authorityData, isHead, options, flag, butText, serviceTypeList) {
    let contractInfoDrawerData = getDrawerData(title, options, flag, butText, serviceTypeList, isHead)
        // 拼接数据显示权限
    contractInfoDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(contractInfoDrawerData, authorityData, isHead)
    console.log("当前仇敌权限数据:", authorityData, contractInfoDrawerData);
    return contractInfoDrawerData
}

export function getDrawerData(title, options, flag, butText, serviceTypeList, isHead) {
   
    let contractInfoDrawerData = {
        data: {
            modelList: []
        }
    }
    let item_data = {}
    switch (flag) {
        case "合同信息":
            contractInfoDrawerData = {
                title: "合同",
                flag: false,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 0,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"

                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }],
                status: "",
                data: {
                    title: title,
                    isLink: true,
                    butArray: butText == "" ? [] : [{
                        text: butText.text,
                        active: butText.active,
                        key: butText.key
                    }],
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    modelList: [{
                            line: [
                                {
                                    horizontal: true,
                                    isShow: false,
                                    isDisable: false,
                                    title: "合同状态",
                                    type: "select",
                                    value: "",
                                    options: [
                                        {
                                            label: '新签',
                                            value: '新签'
                                        },
                                        {
                                            label: '续签',
                                            value: '续签'
                                        }
                                    ],
                                    key: "renewalStatusForReport",
                                }, 
                                {
                                    horizontal: true,
                                    isShow: false,
                                    isDisable: false,
                                    title: "审核状态",
                                    type: "text",
                                    value: "执行中",
                                    key: "status"
                                }, 
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "签单人",
                                    type: "selects-obj-text",
                                    value: "",
                                    options: options.allUser,
                                    key: "single",
                                    flag: false,
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "创建人",
                                    type: "text",
                                    value: "",
                                    options: [],
                                    key: "creatName"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: true,
                                    title: "签单日期",
                                    type: "time",
                                    value: "",
                                    key: "signTime"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "来源",
                                    type: "select",
                                    value: "",
                                    options: options.sourceList,
                                    key: "customerSource"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    marked: false,
                                    title: "标准金额",
                                    type: "text",
                                    value: "",
                                    key: "productPrice"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    marked: true,
                                    title: "成本",
                                    type: "input",
                                    value: "",
                                    key: "cost"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "是否优惠",
                                    type: "radioChange",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                    key: "isDiscounts"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    marked: false,
                                    title: "优惠金额",
                                    type: "input",
                                    value: "0",
                                    key: "discounts"
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    marked: false,
                                    title: "合同金额",
                                    type: "text",
                                    value: "",
                                    key: "money"
                                },
                            ],
                        },
                        {
                            line: [{
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "服务名称",
                                    type: "select",
                                    value: "",
                                    options: options.productList,
                                    key: "productName"
                                }, {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "合同编号",
                                    type: "input",
                                    value: "",
                                    key: "contractNumber"
                                },
                                {
                                    horizontal: true,
                                    isShow: false,
                                    isDisable: false,
                                    title: "续签状态",
                                    type: "select",
                                    value: "",
                                    options: [
                                        {
                                            label: '未续签',
                                            value: '未续签'
                                        },
                                        {
                                            label: '已建续签合同',
                                            value: '已建续签合同'
                                        },
                                        {
                                            label: '已结束续签',
                                            value: '已结束续签'
                                        }
                                    ],
                                    key: "renewStatus"
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    monthDisable: false,
                                    title: "签约期限",
                                    type: "select-input-but",
                                    value: ["", ""],
                                    buts: [],
                                    key: "contractLimit"
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "合同有效期",
                                    type: "time-text",
                                    value: { time1: "", time2: "" },
                                    textValue: "",
                                    key: "validDate"
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "自动续签",
                                    type: "radio",
                                    value: "Y",
                                    options: [{
                                        label: "Y",
                                        text: "是"
                                    }, {
                                        label: "N",
                                        text: "否"
                                    }, ],
                                    key: "autoRenew"
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "是否赠送服务",
                                    type: "radioChange",
                                    value: "N",
                                    options: [{
                                        label: "Y",
                                        text: "是",
                                    }, {
                                        label: "N",
                                        text: "否",
                                    }, ],
                                    key: "isFree"
                                },
                            ],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "",
                                tabletext: "赠送的服务项目",
                                type: "give-service-item",
                                contactMoney: "2200.00", // 合同金额
                                value: [],
                                buttons: [{
                                    text: "添加服务项",
                                    active: "add_free_serves",
                                    key: "edit"
                                }, ],
                                key: "freeServes"
                            }, ],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "",
                                tabletext: "产品名称",
                                type: "text-proNum",
                                value: [],
                                option: '',
                                buttons: [{
                                    text: "添加名称",
                                    active: "add_text_tag",
                                    key: "edit"
                                }, ],
                                key: "proNum"
                            }, ],
                        }, {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "合同备注",
                                type: "textarea",
                                value: "",
                                key: "remark"
                            }, ]
                        }
                    ],
                },
            }
            break;
        case "收款记录":
            item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "table",
                    summary: false,
                    menu: [{
                        key: "voucherNo",
                        value: "收款单号",
                        type: "text",
                        width: "",
                    }, {
                        key: "money",
                        value: "收款金额",
                        type: "text",
                        width: "80",
                    }, {
                        key: "auditedIncome",
                        value: "已收款",
                        type: "text",
                        width: "80",
                    }, {
                        key: "accruedAssets",
                        value: "未收款",
                        type: "text",
                        width: "80",
                    }, {
                        key: "addTime",
                        value: "收款时间",
                        width: "80",
                        type: "text",
                    }, {
                        key: "status",
                        value: "收款状态",
                        type: "text",
                        width: "80",
                    }, {
                        key: "payee",
                        value: "收款人",
                        type: "text",
                        width: "100",
                    }, {
                        key: "",
                        value: "附件",
                        type: "file",
                        width: "100",
                    }, 
                    // {
                    //     value: "操作",
                    //     type: "refund",
                    //     width: "80",
                    // },
                    {
                        // key: "certificate",
                        key: "",
                        value: "操作",
                        type: "buts-dropdown-contract",
                        width: "65",
                        buts: [
                            { name: "退款", active: "refund",  key: "refund",},
                            { name: "修改收款时间", active: "editTime", key: "editTime",},
                        ]
                    }
                ],
                    tableData: []
                }]
            }
            contractInfoDrawerData = {
                title: "合同",
                flag: true,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 1,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"

                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }],
                data: {
                    title: title,
                    isLink: true,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    butArray: [],
                    modelList: [item_data]
                }
            }
            break;
        case "收费情况":
            item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "table",
                    summary: true,
                    menu: [{
                        key: "serviceList",
                        value: "服务项",
                        type: "text",
                        width: "",
                    }, {
                        key: "realMoney",
                        value: "总应收",
                        width: "150",
                        type: "text",
                    }, {
                        key: "received",
                        value: "已收",
                        type: "text",
                        width: "150",
                    }, {
                        key: "unreceived",
                        value: "未收",
                        type: "text",
                        width: "150",
                    }, ],
                    tableData: []
                }]
            }
            contractInfoDrawerData = {
                title: "合同",
                flag: true,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 2,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"

                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }, ],
                data: {
                    title: title,
                    isLink: true,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    butArray: [],
                    modelList: [item_data]
                }
            }
            break;
        case "合同支出":
            item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "expend",
                    value: {
                        button_add: 1,
                        money: "",
                        data: []
                    }
                }]
            }
            contractInfoDrawerData = {
                title: "合同",
                flag: true,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 3,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"

                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }, ],
                data: {
                    title: title,
                    isLink: true,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    butArray: [],
                    modelList: [item_data]
                }
            }
            break;
        case "合同附件":
            item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "fujian",
                    value: {
                        data: [],
                        action: Vue.prototype.$fileBaseurl + "/uploadImageByMultipartFile",
                        headers: {
                            token: "95931b371eb64f4a9c862983ca8a5d15",
                        },
                        uploadParameter: {
                            systemName: "finance",
                            title: "合同附件",
                        },
                    }
                }]
            }
            contractInfoDrawerData = {
                title: "合同",
                flag: true,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 4,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"
                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }, ],
                data: {
                    title: title,
                    isLink: true,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    butArray: [],
                    modelList: [item_data]
                }
            }
            break;
        case "合同发票":
            item_data = {
                line: [{
                    horizontal: true,
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "table-lazy",
                    summary: true,
                    menu: [{
                        key: "buyerName",
                        value: "购买方名称",
                        type: "link",
                        width: "200",
                    }, {
                        key: "status",
                        value: "发票状态",
                        type: "text",
                        width: "100",
                    }, {
                        key: "type",
                        value: "发票种类",
                        type: "invoiceType",
                        width: "100",
                    },{
                        key: "totalAmount",
                        value: "总金额",
                        type: "text", 
                        width: "100",
                    }, {
                        key: "billingDate",
                        value: "开票日期",
                        type: "text",
                        width: "150",
                    },],
                    tableData: []
                }]
            }
            contractInfoDrawerData = {
                title: "合同",
                flag: true,
                CardList: [{
                        text: "合同信息",
                        active: "info",
                        key: "show"
                    }, {
                        text: "收款记录",
                        active: "huikuan",
                        key: "showRecords"
                    },
                    {
                        text: "收费情况",
                        active: "shoufei",
                        key: "showChargeIs"
                    }, {
                        text: "合同支出",
                        active: "zhichu",
                        key: "showSpendAttachment"
                    },
                    {
                        text: "合同附件",
                        active: "fujian",
                        key: "showAttachmen"
                    },
                    {
                        text: "合同发票",
                        active: "fapiao",
                        key: "showContractInvoice"
                    },
                ],
                cardIndex: 5,
                hasSetting: true,
                settingOperList: [{
                    text: "删除合同",
                    active: "delete_contract",
                    key: "delete"
                }, {
                    text: "手动结束",
                    active: "end_the_manual",
                    key: "finish"

                }, {
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "发票申领",
                    icon: "el-icon-help",
                    active: "make_invoice",
                    key: "makeInvoice"
                }, {
                    text: "回款",
                    icon: "el-icon-money",
                    active: "add_huikuan",
                    key: "receivable"
                }, ],
                data: {
                    title: title,
                    isLink: true,
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    butArray: [],
                    modelList: [item_data]
                }
            }
            break;
        case "合同派工":
            contractInfoDrawerData = {
                title: "合同派工",
                flag: false,
                hasSetting: true,
                status: "可提交", // 当前派工状态
                settingOperList: [{
                    text: "操作记录",
                    active: "operating_record_contract",
                    key: "cancel"
                }, ],
                buttonList: [{
                    text: "角色权限",
                    icon: "el-icon-connection",
                    active: "role_authority",
                    key: "roleAuthority"
                }, ],
                data: {
                    titleEdit: true,
                    title: title,
                    butArray: [],
                    customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
                    isDisable: false, // 是否只读
                    modelList: [{
                            line: [{
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "合同编号",
                                type: "text",
                                value: "",
                                key: "contractNum"
                            }, {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "客户编号",
                                type: "text",
                                value: "",
                                key: "clientNum"
                            },
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                border: true,
                                title: "服务产品",
                                type: "text",
                                value: "",
                                key: "product"
                            },   
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "提交人",
                                type: "text",
                                value: "",
                                key: "submitter"
                            }, 
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "合同状态",
                                type: "text",
                                value: "",
                                key: "status"
                            },  
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "服务顾问",
                                type: "textArray",
                                value: "",
                                key: "serviceConsultant"
                            }, 
                            {
                                horizontal: true,
                                isShow: false,
                                isDisable: false,
                                title: "下单时间",
                                type: "text",
                                value: "",
                                key: "orderTime"
                            }, 
                            
                        ],
                        },
                        {
                            line: [{ // 服务项目
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "",
                                tabletext: "项目步骤",
                                type: "step",
                                value: [],
                                key: "projectStep"
                            }, ]
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true, 
                                isDisable: false,
                                title: "",
                                tabletext: "赠送的服务项目",
                                type: "give-service-item",
                                contactMoney: "2200.00", // 合同金额
                                value: [],
                                key: "give",
                                // buttons: [{
                                //     text: "添加服务项",
                                //     active: "add_free_serves",
                                //     // authority: buttonAuth.length > 0 ? baseData.judgeButtonAuthority("添加服务项目", buttonAuth) : 1
                                // }, ]
                            }, ]
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "业务类型名称",
                                type: "select",
                                isDisable: isHead ? false : true,
                                value: "",
                                options: serviceTypeList,
                                key: "professionName"
                            }, {
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "业务类型备注",
                                type: "textarea",
                                value: "",
                                key: "professionDes"
                            }],
                        },
                        {
                            line: [{
                                horizontal: false, 
                                isShow: false,
                                isDisable: true,
                                title: "合同备注",
                                type: "textarea-isDisable",
                                value: "",
                                key: "remark"
                            }],
                        },
                        {
                            line: [{
                                horizontal: false, 
                                isShow: false,
                                isDisable: false,
                                title: "", // 项目记录
                                type: "projectRecord",
                                value: "", 
                                key: "itemRecord"
                            }],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "项目要求",
                                type: "textarea",
                                value: "",
                                key: "projectRequirement"
                            }],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "项目所需资料",
                                type: "textarea",
                                value: "",
                                key: "projectRequiredInformation"
                            }],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "项目成果",
                                type: "textarea",
                                value: "",
                                key: "projectResults"
                            }],
                        },
                    ],
                },
            }
            break

        default:
            break;
    }
    return contractInfoDrawerData
}

export function returnDrawerKeys(flag) {
    let keys = []
    let drawerData = getDrawerData("", Vue.prototype.$tagData.getDrawerOptionsList("contractInfo"), flag, {}).data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.hasOwnProperty('key')) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    console.log("拿到当前抽屉的所有keys", drawerData, keys);
    return keys
}

export function initData(data, drawerData, allFunctionButtons, isCharge, model) {
    let flag = false
    let statusList = Vue.prototype.$statusData.getStatus(data.data.status, 'contractInfo')
    if (isCharge) {
        //是机构负责人
        flag = true
    } else {
        // for (let index = 0; index < allFunctionButtons.length; index++) {
        //     let element = allFunctionButtons[index];
        //     if (element.key == "edit" && element.authority == "1") {
        //         if (statusList.indexOf("edit") >= 0) {
        //             // 叠加显示状态
        //             flag = true 
        //         } 
        //     }
        // }

        if (data.data.status == "待提交" || data.data.status == "合同审核不通过" ) {
            flag = true
        }
        
        if (data.data.projectStep && data.data.status == "执行中") {
            flag = true
        }
    } 

    console.log("当前编辑权限", data, flag, statusList, isCharge, allFunctionButtons);

    for (let index = 0; index < drawerData.data.modelList.length; index++) {
        let element = drawerData.data.modelList[index];
        for (let i = 0; i < element.line.length; i++) {
            let ele = element.line[i];
            if (ele.key == "contractLimit" || ele.key == "validDate") {
                if (data.data.validDate.time2 == "--") {
                    console.log("一次性合同");
                    ele.isShow = false
                    ele.isDisable = true
                }
            }
            if (!flag) {
                ele.isDisable = true
            } else {
                if (ele.key == "discounts") {
                    // 优惠金额
                    ele.isShow = ele.isShow ? data.data.isDiscounts == "Y" : ele.isShow
                    ele.isDisable = !ele.isDisable ? data.data.isDiscounts == "N" : ele.isDisable
                    ele.value = data.data.discounts == "" ? 0 : data.data.discounts
                }
                if (ele.key == "freeServes") {
                    // 赠送服务项
                    ele.isShow = ele.isShow ? data.data.isFree == "Y" : ele.isShow
                    ele.isDisable = !ele.isDisable ? data.data.isFree == "N" : ele.isDisable
                    if (data.data.freeServes != "") {
                        for (let j = 0; j < data.data.freeServes.length; j++) {
                            let e = data.data.freeServes[j];
                            Vue.set(e, "isEdit", false)
                        }
                    }
                    ele.value = data.data.freeServes == "" ? [] : data.data.freeServes
                }
                if (ele.key == "proNum") {
                    // 产品数量
                    ele.isShow = data.data.proNum == 'a' ? false : ele.isShow
                    ele.isDisable = !ele.isShow
                    if (data.data.proNum !== "a") {
                        for (let j = 0; j < data.data.proNum.length; j++) {
                            let e = data.data.proNum[j];
                            Vue.set(e, "isEdit", false)
                        }
                    }
                    ele.value = data.data.proNum == "" ? [] : data.data.proNum
                }
            }

        }

    }
}

/**
 * 修改客户业务角色信息
 * @param {*} roleList 
 * @param {*} employeeList 
 */
export function clientConnectDialogData(roleList, employeeList) {
    let dialogData = []

    roleList.forEach(role => {
        let dd = {
            type: "tips_selects_dis",
            id: role.id,
            title: role.name,
            flag: role.flag,
            value: role.users,
            options: employeeList,
            inputVisible: false,
            isShow: role.name == "合同审核员" || role.name == "出纳" ? false : true,
            tempValue: {}
        }
        dialogData.push(dd)
    });
    console.log("当前dialog权限", dialogData);
    return dialogData
}

/**
 * 格式化客户业务角色信息信息
 * @param {*} data 
 */
export function formateClientConnectDialogData(data) {
    let list = []
    data.forEach(element => {
        let users = []
        element.value.forEach(ele => {
            users.push(ele.id)
        });
        let dd = {
            flag: element.flag,
            name: element.title,
            users: users,
            id: element.id,
        }
        list.push(dd)
    });
    return list
}

/**
 * 合同支付 dialog
 * @param {*} _this
 * @param {*} payOptions 付款方式列表
 * @param {*} receiptNumber 收款单号
 * @param {*} currentData 现在的数据
 * @param {*} model 模块
*/
export function contractExpend(_this, payOptions, receiptNumber, currentData, model) {
   let data = {
    disabled: false,
    reasons: currentData ? currentData.reasons : '', // 付款理由
    receiptNumber: receiptNumber ? receiptNumber : currentData ?  currentData.receiptNumber : '', // 收款单号
    paymentAmount: currentData ? currentData.paymentAmount.toString() : '', // 付款金额
    paymentMethod: currentData ? currentData.paymentMethod : '', // 付款方式
    paymentDate: currentData ? currentData.paymentDate : '', // 付款日期
    payeeName: currentData ? currentData.payeeName : '', // 收款人全称
    bankAccount: currentData ? currentData.bankAccount : '', // 银行账户
    bankOfDeposit: currentData ? currentData.bankOfDeposit : '', // 开户行
    remark: currentData ? currentData.remark : '', // 备注说明
    attachments:currentData ? currentData.attachments.split(',') : [], // 附件
    payer: currentData ? currentData.payer : '', // 付款单位
    payerOwner: currentData ? currentData.payerOwner : '', // 付款单位
    payerOptions: payOptions, // 付款方式列表
    paymentMethodOptions: [
        {
          label: '银行卡',
          value: '银行卡'
        },
        {
          label: '现金',
          value: '现金'
        },
        {
          label: '汇票',
          value: '汇票'
        },
        {
          label: '电汇',
          value: '电汇'
        },
        {
          label: '贷记',
          value: '贷记'
        },
        {
          label: '支票',
          value: '支票'
        },
        {
          label: '其他',
          value: '其他'
        }
    ], // 付款单位列表
    uploadData: {
        title: "上传附件",
        type: "upload",
        value: {
          value: '',
          action: _this.$fileBaseurl + "/uploadImageByMultipartFile",
          headers: {
            token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          uploadParameter: {
            systemName: "finance",
            title: "最新政策",
          },
        },
    },
   }
   // 如果 是合同模块的添加支出 且 没有收款单号 收款单号进制输入
   if (model == 'contract' && data.receiptNumber == '') {
    data.disabled = true
   }
   return data
}

/**
 * 合同支付数据转换
*/
export function contractExpendData(data) {
    data.forEach(
        (item) => {
          item.attachments = item.attachments.split(",");
          switch (item.reimburse.status) {
            case "1":
              item.reimburse.status = "审批中";
              item.reimburse.color = "#409EFF";
              break;
            case "2":
              item.reimburse.status = "已驳回";
              item.reimburse.color = "#F56C6C";
              break;
            case "3":
              item.reimburse.status = "已撤销";
              item.reimburse.color = "";
              break;
            case "4":
              item.reimburse.status = "待付款";
              item.reimburse.color = "#E6A23C";
              break;
            case "5":
              item.reimburse.status = "已完成";
              item.reimburse.color = "#67C23A";
              break;
            default:
              break;
          }
        }
      );
    return data
}