<template>
  <!-- 编辑器 -->
  <div style="border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc;"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 200px;  overflow-y: hidden;  text-align: left;"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import { Boot } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import attachmentModule from "@wangeditor/plugin-upload-attachment";
Boot.registerModule(attachmentModule);
export default {
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  watch: {
    html(newVal) {
      this.$emit("setHtml", newVal);
    },
  },
  data() {
    return {
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {
        insertKeys: {
          index: 0,
          keys: ["uploadAttachment"],
        },
      },
      editorConfig: {
        placeholder: "请输入内容...",
        hoverbarKeys: {
          attachment: {
            menuKeys: ["downloadAttachment"], // “下载附件”菜单
          },
        },
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            server: this.$fileBaseurl + "/uploadImageByMultipartFile",
            fieldName: "img",
            meta: {
              systemName: "finance",
              title: "编辑器上传图片",
            },
            headers: {
              token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            customInsert(res, insertFn) {
              insertFn(res.result.path, res.result.title);
            },
          },
          uploadAttachment: {
            server: this.$fileBaseurl + "/uploadImageByMultipartFile",
            fieldName: "file",
            meta: {
              systemName: "finance",
              title: "编辑器上传文件",
            },
            headers: {
              token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            maxFileSize: 10 * 1024 * 1024, // 10M
            onBeforeUpload(file) {
              console.log("onBeforeUpload", file);
              return file; // 上传 file 文件
            },
            onProgress(progress) {
              console.log("onProgress", progress);
            },
            onSuccess(file, res) {
              console.log("onSuccess", file, res);
            },
            onFailed(file, res) {
              console.log("onFailed", file, res);
            },
            onError(file, err, res) {
              console.error("onError", file, err, res);
            },
            onInsertedAttachment(elem) {
              console.log("inserted attachment", elem);
            },
          },
          // 继续其他菜单配置...
        },
      },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    // this.editor.txt.html(this.content);
    setTimeout(() => {
      this.html = this.content;
    }, 10);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
