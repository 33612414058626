<template>
  <div id="notice-detail">
    <div class="notice-detail-title">{{ data.title }}</div>
    <div class="notice-detail-time">{{ data.gmtModified }}</div>
    <div
      class="notice-detail-content"
      v-html="data.content"
      v-viewer="{ movable: false }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    getImg($event) {
      console.log($event);
      console.log($event.target.currentSrc);
      // ImagePreview({
      //   images:[
      //     $event.target.currentSrc
      //   ],
      //   showIndex:false
      // })
    },
  },
};
</script>

<style lang="less" scoped>
#notice-detail {
  //   position: relative;
  width: 100%;
  min-height: 600px;

  .notice-detail-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    // position: absolute;
    // top: 0;
    // left: 50%;
  }
  .notice-detail-time {
    margin: 10px 0 20px 0;
    text-align: center;
  }
  .notice-detail-content {
    overflow-x: auto;
    max-height: 700px;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
