<template>
  <div id="my_drawer">
    <div class="my_drawer-active" @click="closeUpdateTableDrawer"></div>
    <div class="my_drawer-content" :style="{ minWidth: minWidth }">
      <div class="my-drawer-title">
        <div class="my-drawer-title-text" v-if="!data.CardList">
          {{ data.title }}
        </div>
        <div class="my-drawer-title-texts" v-if="data.CardList">
          <div class="my-drawer-title-text">{{ data.title }}</div>
          <div class="my-drawer-title-texts-cards">
            <div
              :class="{
                'my-drawer-title-texts-card': true,
                'is-active': data.cardIndex == index,
              }"
              v-for="(item, index) in data.CardList"
              :key="index"
              @click="drawerOper(item.active, index)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="my-drawer-title-opers">
          <div
            class="my-drawer-title-oper"
            v-for="(item, index) in data.buttonList"
            :key="index"
            v-showFunction="{
              active: item.key,
              array: allFunctionButtons,
              model: turemodel,
              status: data.status ? data.status : '',
            }"
            @click="drawerOper(item.active)"
          >
            <i :class="item.icon"></i>{{ item.text }}
          </div>
        </div>
        <div class="my-drawer-title-setting" v-if="data.hasSetting">
          <el-popover
            placement="bottom"
            width="80"
            trigger="hover"
            :visible-arrow="false"
          >
            <div class="main-top-userInfo-float">
              <div class="main-top-userInfo-float-operlist">
                <div
                  class="main-top-userInfo-float-oper"
                  style="height: 30px; cursor: pointer"
                  v-for="(item, index) in data.settingOperList"
                  :key="index"
                  v-showFunction="{
                    active: item.key,
                    array: allFunctionButtons,
                    model: turemodel,
                    status: data.status ? data.status : '',
                  }"
                  @click="drawerOper(item.active)"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div slot="reference" class="main-top-userInfo-show">
              <i class="el-icon-setting"></i>
            </div>
          </el-popover>
        </div>
        <div class="my-drawer-title-close" @click="closeUpdateTableDrawer">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="my-drawer-content">
        <div class="my-drawer-content-title" v-if="data.data.title">
          <i class="el-icon-office-building"></i>
          <div class="my-drawer-content-title-name" v-if="data.data.titleEdit">
            <el-input
              v-model="data.data.title"
              placeholder="请输入内容"
              @change="saveDrawerData('客户名称')"
            ></el-input>
          </div>
          <div
            class="
              my-drawer-content-title-name my-drawer-content-title-name-link
            "
            v-else-if="data.data.isLink"
          >
            <span @click="linkClitent">{{ data.data.title }}</span>
          </div>
          <div class="my-drawer-content-title-name" v-else>
            {{ data.data.title }}
          </div>
          <div style="color: #000; fontSize: 14px">
            总金额: {{ data.data.allTotalAmount || 0 }}
          </div>
          <div v-if="data.data.butArray">
            <template v-for="(item, index) in data.data.butArray">
              <div
                class="my-drawer-content-title-but"
                :key="index"
                @click="drawerOper(item.active)"
                v-showFunction="{
                  active: item.key,
                  array: allFunctionButtons,
                  status: data.status ? data.status : '',
                  model: turemodel,
                }"
              >
                {{ item.text }}
              </div>
            </template>
          </div>
          <div
            class="my-drawer-content-title-customer"
            v-if="data.data.customer > 0"
          >
            <div class="my-drawer-content-title-customer-text">
              <el-badge :value="data.data.customer" class="item">
                <el-popover placement="bottom" width="400" trigger="click">
                  <el-table :data="data.data.gridData" height="30vh">
                    <el-table-column
                      property="name"
                      label="客户名称"
                    ></el-table-column>
                    <el-table-column label="操作" width="100">
                      <template slot="header">
                        <div
                          class="my-drawer-content-title-customer-text-header"
                          @click="addRelevanceClient"
                        >
                          操作 <i class="el-icon-circle-plus-outline"></i>
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-button
                          @click="deleteRelevanceClient(scope.row.id)"
                          type="text"
                          size="small"
                          :style="{
                            color: 'red',
                          }"
                          >解除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button
                    size="small"
                    slot="reference"
                    @click="showRelevanceClient"
                    >关联客户</el-button
                  >
                </el-popover>
              </el-badge>
            </div>
          </div>
        </div>
        <div class="my-drawer-content-search" v-if="data.title == '发票'">
          <div class="my-drawer-content-search-line">
            <div class="my-drawer-content-search-item">
              <el-input
                v-model="data.data.buyerName"
                placeholder="请输入购买方名称"
                @keyup.enter.native="drawerOper('search')"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click.native.stop="drawerOper('search')"
                ></el-button>
              </el-input>
            </div>
            <div class="my-drawer-content-search-item">
              <el-date-picker
                v-model="data.data.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="drawerOper('search')"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="my-drawer-content-search-line">
            <div class="my-drawer-content-search-item">
              <el-cascader
                v-model="data.data.type"
                :options="data.data.options"
                placeholder="请选择发票类型"
                :props="{
                  multiple: true,
                  emitPath: false,
                }"
                collapse-tags
                clearable
                filterable
                @change="drawerOper('search')"
              ></el-cascader>
            </div>
          </div>
        </div>
        <div
          class="my-drawer-content-search"
          v-if="data.title == '客户' && data.cardIndex == 5"
        >
          <div class="my-drawer-content-search-line-byClient">
            <div class="my-drawer-content-search-item">
              <el-input
                v-model="data.data.input"
                placeholder="请输入文件名称"
                @keyup.enter.native="drawerOper('search')"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click.native.stop="drawerOper('search')"
                ></el-button>
              </el-input>
            </div>
            <div
              class="my-drawer-content-button-item"
              v-if="data.data.topButArray"
            >
              <template v-for="(item, index) in data.data.topButArray">
                <el-upload
                  v-if="index == 0 && !item.isDisable"
                  :key="index"
                  multiple
                  v-showFunction="{
                    active: item.key,
                    array: allFunctionButtons,
                  }"
                  class="avatar-uploader"
                  :action="data.data.upload.value.action"
                  :show-file-list="false"
                  :headers="data.data.upload.value.headers"
                  :data="data.data.upload.value.uploadParameter"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <el-button size="mini" type="primary">{{
                    item.text
                  }}</el-button>
                </el-upload>
                <el-button
                  v-else-if="!item.isDisable"
                  v-showFunction="{
                    active: item.key,
                    array: allFunctionButtons,
                  }"
                  :key="index"
                  size="mini"
                  type="primary"
                  plain
                  @click="drawerOper(item.active, index)"
                  >{{ item.text }}</el-button
                >
              </template>
            </div>
          </div>
        </div>
        <div
          class="my-drawer-content-tips"
          v-if="data.title == '客户' && data.cardIndex == 5"
        >
          <div
            :class="{
              'my-drawer-content-tip': !isLastItem(tipi),
            }"
            v-for="(tip, tipi) in data.data.fileOrder"
            :key="tipi"
          >
            <span @click="tipChange({ tip, tipi })">{{ tip.name }}</span>
            <span v-if="!isLastItem(tipi)" style="margin: 0 2px"
              ><i class="el-icon-arrow-right"></i
            ></span>
          </div>
        </div>
        <div
          class="my-drawer-content-model"
          v-show="!(item.line.length == 1 && !item.line[0].isShow)"
          v-for="(item, index) in data.data.modelList"
          :key="index"
        >
          <div
            :class="{
              'my-drawer-content-model-line': it.isShow,
            }"
            v-for="(it, ii) in item.line"
            :key="ii"
          >
            <div
              v-if="it.marked"
              class="my-drawer-content-model-line-key-marked"
            >
              *
            </div>
            <div
              class="my-drawer-content-model-line-key"
              v-if="it.type != 'record' && it.title != '' && it.isShow"
            >
              {{ it.title }}
            </div>

            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'text' && it.isShow"
            >
              {{ it.value }}
            </div>

            <div
              class="my-drawer-content-model-line-value"
              style="white-space: pre-wrap"
              v-if="it.type == 'html' && it.isShow"
              v-html="it.value"
            ></div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'text-button' && it.isShow"
            >
              {{ it.value }}
              <div
                class="my-drawer-content-model-line-value-but"
                @click="drawerOper(it.active)"
                v-showFunction="{
                  active: it.roleKey,
                  array: allFunctionButtons,
                }"
              >
                {{ it.text }}
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'text-color' && it.isShow"
            >
              <div
                v-for="(ida, i) in it.value"
                :key="i"
                :class="{ 'table-tip': it.border }"
                :style="{
                  color: ida.color,
                  borderColor: ida.color,
                  height: '25px',
                }"
              >
                {{ ida.text }}
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'textArray' && it.isShow"
            >
              <div
                class="my-drawer-content-model-line-value-text"
                v-for="(ida, i) in it.value"
                :key="i"
              >
                <div v-if="i == 0">
                  {{ ida }}
                </div>
                <div v-else>,{{ ida }}</div>
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              @copy="onCopy(it.key1, data.data.title, it.value)"
              v-if="it.type == 'input' && it.isShow"
            >
              <span
                v-if="
                  data.title == '客户' &&
                    data.CardList[1].text == '联系人' &&
                    it.key1 == 'phone'
                "
                @click="callNumber(it.value)"
              >
                <i class="el-icon-phone-outline phone"></i>
              </span>
              <el-input
                v-model="it.value"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
              ></el-input>
            </div>
            <div
              class="my-drawer-content-model-line-value hr"
              v-if="it.type == 'linkman' && it.isShow"
            >
              <!-- 商机中的联系人 -->
              <div class="hr-value" v-for="(tt, iii) in it.value" :key="iii">
                <div class="hr-value-title">
                  <div class="hr-value-span">联系人</div>
                  <div class="hr-value-va">
                    <el-input
                      v-model="tt.name"
                      :disabled="it.isDisable"
                      placeholder="请输入内容"
                      @change="saveDrawerData(it.title)"
                    ></el-input>
                  </div>
                </div>
                <div class="hr-value-title">
                  <div class="hr-value-span">手机</div>
                  <div
                    class="hr-value-va"
                    @copy="onCopy(it.key, data.data.title, tt.phone[0])"
                  >
                    <el-input
                      v-model="tt.phone[0]"
                      :disabled="it.isDisable"
                      placeholder="请输入内容"
                      :suffix-icon="
                        tt.error[0] ? 'el-icon-warning-outline' : ''
                      "
                      @change="saveDrawerData(it.title)"
                    ></el-input>
                    <span class="hr-value-icon">
                      <i
                        class="el-icon-phone-outline phone"
                        @click="callNumber(tt.phone[0])"
                      ></i>
                    </span>
                    <el-input
                      v-model="tt.phone[1]"
                      :disabled="it.isDisable"
                      placeholder="请输入内容"
                      :suffix-icon="
                        tt.error[1] ? 'el-icon-warning-outline' : ''
                      "
                      @change="saveDrawerData(it.title)"
                    ></el-input>
                  </div>
                  <div class="hr-value-icon" v-if="iii == it.value.length - 1">
                    <!-- <i class="iconfont icon-yichang"></i> -->
                    <i
                      class="el-icon-circle-plus-outline"
                      @click="addLinkman(tt, it, index, ii)"
                    ></i>
                  </div>
                  <div class="hr-value-icon" v-if="iii > 0">
                    <i
                      class="el-icon-remove-outline"
                      @click="cancelLinkman(tt, it, index, ii)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-new' && it.isShow"
            >
              <el-input
                v-model="it.value"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @keyup.enter.native="enter"
              ></el-input>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-autocomplete' && it.isShow"
            >
              <el-autocomplete
                class="inline-input"
                v-model="it.value"
                :disabled="it.isDisable"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                placeholder="请输入内容"
                @keyup.enter.native="enter"
              ></el-autocomplete>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-autocomplete-tooltip' && it.isShow"
            >
              <el-tooltip
                :content="it.message"
                :disabled="it.tooltipf_flag"
                placement="bottom-start"
                effect="light"
              >
                <el-autocomplete
                  class="inline-input"
                  v-model="it.value"
                  :disabled="it.isDisable"
                  :fetch-suggestions="querySearch"
                  :trigger-on-focus="false"
                  placeholder="请输入内容"
                  @keyup.enter.native="enter"
                ></el-autocomplete>
              </el-tooltip>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-select-filterable-tooltip' && it.isShow"
            >
              <el-tooltip
                :content="it.message"
                :disabled="it.tooltipf_flag"
                placement="bottom-start"
                effect="light"
              >
                <el-select
                  v-model="it.value"
                  value-key="value"
                  filterable
                  placeholder="请选择"
                  @change="enter"
                >
                  <el-option
                    v-for="item in data.data.list"
                    :key="item.value"
                    :label="item.label"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-but' && it.isShow"
            >
              <el-input
                v-model="it.value"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
              ></el-input>
              <div
                class="my-drawer-content-model-line-value-but"
                v-for="tt in it.buts"
                :key="tt.active"
                @click="lineOper(tt.active, index)"
              >
                {{ tt.text }}
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'input-buts' && it.isShow"
            >
              <el-input
                v-model="it.value"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
              ></el-input>
              <div
                class="my-drawer-content-model-line-value-but"
                v-for="tt in it.buts"
                :key="tt.active"
                @click="lineOpers(tt.active, index)"
              >
                {{ tt.text }}
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value short-input"
              v-if="it.type == 'select-input-but' && it.isShow"
            >
              <el-input
                v-model="it.value[0]"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
                min="0"
                type="number"
              ></el-input
              >年<el-input
                v-model="it.value[1]"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
                type="number"
                max="11"
                min="0"
              ></el-input
              >月
              <div
                class="my-drawer-content-model-line-value-but"
                v-for="tt in it.buts"
                :key="tt.active"
                @click="lineOper(tt.active, index)"
                v-show="tt.authority == 1"
              >
                {{ tt.text }}
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              style="white-space: pre-wrap"
              v-if="it.type == 'textarea' && it.isShow"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="it.value"
                :disabled="it.isDisable"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
              ></el-input>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              style="white-space: pre-wrap"
              v-if="it.type == 'textarea-isDisable' && it.isShow"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="it.value"
                :disabled="true"
                placeholder="请输入内容"
                @change="saveDrawerData(it.title)"
              ></el-input>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'select' && it.isShow"
            >
              <el-select
                v-model="it.value"
                filterable
                placeholder="请选择"
                :disabled="it.isDisable"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'selects' && it.isShow"
            >
              <!-- 对象 -->
              <el-select
                v-model="it.value"
                filterable
                placeholder="请选择"
                :disabled="it.isDisable"
                value-key="label"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'selects-label' && it.isShow"
            >
              <!-- 对象 -->
              <el-select
                v-model="it.value"
                placeholder="请选择"
                filterable
                :disabled="it.isDisable"
                value-key="label"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'selects-obj' && it.isShow"
            >
              <!-- 对象 -->
              <el-select
                v-model="it.value"
                placeholder="请选择"
                filterable
                :disabled="it.isDisable"
                value-key="label"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'selects-obj-text' && it.isShow"
            >
              <!-- 对象 -->
              <el-select
                v-if="it.flag"
                v-model="it.value"
                placeholder="请选择"
                filterable
                :disabled="it.isDisable"
                value-key="label"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt"
                >
                </el-option>
              </el-select>
              <span v-else @click="it.flag = !it.flag">{{
                it.value.label
              }}</span>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'selects-multiple' && it.isShow"
            >
              <!-- 数组 -->
              <el-select
                v-model="it.value"
                placeholder="请选择"
                multiple
                filterable
                :disabled="it.isDisable"
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.value"
                  :label="tt.label"
                  :value="tt.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'select-allow-create' && it.isShow"
            >
              <el-select
                v-model="it.value"
                filterable
                allow-create
                placeholder="请选择"
                :disabled="it.isDisable"
                clearable
                @change="saveDrawerData(it.title)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.label"
                  :label="tt.label"
                  :value="tt.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'time' && it.isShow"
            >
              <el-date-picker
                v-model="it.value"
                type="date"
                :disabled="it.isDisable"
                placeholder="选择日期"
                @change="saveDrawerData(it.title)"
                value-format="yyyy-MM-dd"
              >
                <!-- @blur="saveDrawerData" -->
              </el-date-picker>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'time-month' && it.isShow"
            >
              <el-date-picker
                v-model="it.value"
                type="month"
                :disabled="it.isDisable"
                placeholder="选择日期"
                @change="saveDrawerData(it.title)"
                value-format="yyyy-MM"
              >
                <!-- @blur="saveDrawerData" -->
              </el-date-picker>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'time-text' && it.isShow"
            >
              <el-date-picker
                v-model="it.value.time1"
                type="date"
                :disabled="it.isDisable"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                @change="saveDrawerData(it.title)"
              >
                <!-- @blur="saveDrawerData" -->
              </el-date-picker>
              <span>至 {{ it.value.time2 }}</span>
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'area' && it.isShow"
            >
              <area-select
                :level="2"
                type="text"
                placeholder="请选择地区"
                v-model="it.value"
                :data="pcaa"
                :disabled="it.isDisable"
              ></area-select>
              <!-- @change="saveDrawerData" -->
            </div>
            <div
              class="my-drawer-content-model-line-value div-wrap"
              v-if="it.type == 'tags' && it.isShow"
            >
              <el-tag
                :key="tag.title"
                v-for="tag in it.value"
                :disable-transitions="false"
                :closable="!it.isDisable"
                @close="handleClose(tag, ii, index)"
                effect="plain"
              >
                {{ tag.title }}
              </el-tag>
              <el-button
                class="button-new-tag"
                size="small"
                :disabled="it.isDisable"
                @click="addTags(index, ii)"
                >+ 添加</el-button
              >
            </div>
            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'tags-select' && it.isShow"
            >
              <el-tag
                :key="tag"
                v-for="tag in it.value"
                :closable="!it.isDisable"
                :disable-transitions="false"
                @close="handleClose(tag, ii, index)"
                effect="plain"
              >
                {{ tag }}
              </el-tag>
              <el-select
                v-model="it.tempValue"
                v-if="it.isSelect"
                filterable
                placeholder="请选择"
                @change="addTags(index, ii)"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.value"
                  :label="tt.label"
                  :value="tt.value"
                >
                </el-option>
              </el-select>
              <el-button
                class="button-new-tag"
                v-else
                size="small"
                :disabled="it.isDisable"
                @click="addTags(index, ii)"
                >+ 添加</el-button
              >
            </div>
            <div
              class="my-drawer-content-model-line-value short"
              v-if="it.type == 'tags-select-obj' && it.isShow"
            >
              <el-tag
                :key="tag.id"
                v-for="tag in it.value"
                :closable="
                  !it.isDisable &&
                    it.title != '合同审核员' &&
                    it.title != '出纳'
                "
                :disable-transitions="false"
                @close="handleClose(tag, ii, index)"
                effect="plain"
              >
                {{ tag.name }}
              </el-tag>
              <el-select
                v-model="it.tempValue"
                v-if="it.isSelect"
                filterable
                placeholder="请选择"
                @change="addTags(index, ii)"
                value-key="id"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.id"
                  :label="tt.name"
                  :value="tt"
                >
                </el-option>
              </el-select>
              <el-button
                class="button-new-tag"
                v-else-if="it.title != '合同审核员' && it.title != '出纳'"
                size="small"
                :disabled="it.isDisable"
                @click="addTags(index, ii)"
                >+ 添加</el-button
              >
            </div>
            <div
              class="my-drawer-content-model-line-value short"
              v-if="it.type == 'tags-select-obj-search' && it.isShow"
            >
              <el-tag
                :key="tag.id"
                v-for="tag in it.value"
                :closable="!it.isDisable"
                :disable-transitions="false"
                @close="handleClose(tag, ii, index)"
                effect="plain"
              >
                {{ tag.name }}
              </el-tag>
              <el-select
                v-model="it.tempValue"
                v-if="it.isSelect"
                placeholder="请选择"
                filterable
                @change="addTags(index, ii)"
                value-key="id"
              >
                <el-option
                  v-for="tt in it.options"
                  :key="tt.id"
                  :label="tt.name"
                  :value="tt"
                >
                </el-option>
              </el-select>
              <el-button
                class="button-new-tag"
                v-else
                size="small"
                :disabled="it.isDisable"
                @click="addTags(index, ii)"
                >+ 添加</el-button
              >
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'record' && it.isShow"
            >
              <div
                class="my-drawer-content-model-line-value-record"
                v-for="(record, i) in it.value"
                :key="i"
              >
                <div class="my-drawer-content-model-line-value-record-icon">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="record.icon"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="my-drawer-content-model-line-value-record-cont">
                  <div class="my-drawer-content-model-line-value-record-cont-n">
                    {{ record.name }}
                  </div>
                  <div class="my-drawer-content-model-line-value-record-cont-l">
                    {{ record.content }}
                  </div>
                  <div class="my-drawer-content-model-line-value-record-cont-l">
                    跟进阶段:{{ record.stage }}
                  </div>
                  <div class="my-drawer-content-model-line-value-record-cont-l">
                    下次联系时间:{{ record.time }}
                  </div>
                </div>
                <div class="my-drawer-content-model-line-value-record-time">
                  {{ record.operTime }}
                </div>
              </div>
              <div
                class="my-drawer-content-model-line-value-record div-center"
                v-if="it.value.length == 0"
              >
                暂无跟进记录!
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'table' && it.isShow"
            >
              <div
                class="my-drawer-content-model-line-value-column-table-title"
                v-if="it.tableTitle"
              >
                {{ it.tableTitle }}
              </div>
              <el-table
                :data="it.tableData"
                style="width: 100%"
                max-height="720"
                :show-summary="it.summary"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  v-if="data.title == '发票'"
                  type="selection"
                  width="35"
                >
                </el-table-column>
                <el-table-column
                  :prop="itd.key"
                  :label="itd.value"
                  v-for="(itd, i) in it.menu"
                  :key="i"
                  show-overflow-tooltip
                  :width="itd.type == 'buts' ? 180 : itd.width"
                  :fixed="itd.key == 'contractNumber'"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="itd.type == 'link'"
                      style="color: #7db1fe; cursor: pointer"
                      @click="linkNow(scope.row, ii)"
                    >
                      <i
                        v-if="
                          scope.row.isUrgent &&
                            (scope.row.status == '待审核' ||
                              scope.row.status == '待提交')
                        "
                        class="iconfont icon-shandianjiaji"
                      ></i>
                      {{ scope.row[itd.key] }}
                    </div>

                    <div v-else-if="itd.type == 'array'" style="display: flex">
                      <div
                        style="margin: 0 2px; cursor: pointer"
                        v-for="ddd in scope.row[itd.key]"
                        :key="ddd.text"
                      >
                        <el-tooltip
                          :content="ddd.tip"
                          placement="bottom"
                          effect="light"
                        >
                          <!-- <el-tag type="success" disable-transitions>{{
                            ddd.text
                          }}</el-tag> -->
                          <div
                            class="table-tip"
                            :style="{
                              borderColor: ddd.color,
                              color: ddd.color,
                            }"
                          >
                            {{ ddd.text }}
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                    <div
                      v-else-if="itd.type == 'text-status'"
                      :style="{ color: scope.row.color }"
                    >
                      {{ scope.row.status }}
                    </div>
                    <div
                      v-else-if="itd.type == 'file'"
                      style="color: #7db1fe; cursor: pointer; font-size: 11px"
                      @click="linkNowFile(scope.row, ii)"
                    >
                      查看附件
                    </div>
                    <div
                      v-else-if="itd.type == 'operating'"
                      style="color: #7db1fe; cursor: pointer; font-size: 11px"
                      @click="linkNowOperating(scope.row, ii)"
                    >
                      查看记录
                    </div>
                    <div v-else-if="itd.type == 'buts'">
                      <template v-for="(but, butIndex) in itd.buts">
                        <el-button
                          :key="butIndex"
                          type="primary"
                          size="mini"
                          :icon="but.icon"
                          @click="
                            drawerOperFile(but.active, scope.row, scope.row.id)
                          "
                          >{{ but.name }}</el-button
                        >
                      </template>
                    </div>
                    <div v-else-if="itd.type == 'buts-dropdown'">
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                          操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="(but, butIndex) in itd.buts">
                            <el-dropdown-item
                              :key="butIndex"
                              v-if="matchStatus(scope.row, but.active)"
                              @click.native="
                                drawerOperArchives(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                              >{{ but.name }}</el-dropdown-item
                            >
                          </template>
                          <el-dropdown-item
                            v-showFunction="{
                              active: 'deleteArchives',
                              array: allFunctionButtons,
                            }"
                            @click.native="
                              drawerOperArchives(
                                'delete',
                                scope.row,
                                scope.row.id
                              )
                            "
                            >删除</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div v-else-if="itd.type == 'buts-dropdown-contract'">
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                          操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="(but, butIndex) in itd.buts">
                            <el-dropdown-item
                              :key="butIndex"
                              v-if="but.key == 'refund'"
                              @click.native="
                                drawerOperContract(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                            >
                              <span v-if="parseFloat(scope.row.money) > 0">{{
                                but.name
                              }}</span></el-dropdown-item
                            >
                            <!-- <el-dropdown-item
                              :key="butIndex"
                              v-else-if="but.key == 'apply'"
                              @click.native="
                                drawerOperContract(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                            >
                              <span
                                v-if="
                                  (parseFloat(scope.row.money) > 0 &&
                                    !scope.row.invoiceId) ||
                                    (!scope.row.invoiceId && whetherAgencyHead)
                                "
                                >{{ but.name }}</span
                              >
                            </el-dropdown-item> -->
                            <el-dropdown-item
                              :key="butIndex"
                              v-else-if="but.key == 'editTime'"
                              @click.native="
                                drawerOperContract(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                            >
                              <span v-if="whetherAgencyHead">{{
                                but.name
                              }}</span>
                            </el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div
                      v-else-if="
                        itd.type == 'refund' && parseFloat(scope.row.money) > 0
                      "
                    >
                      <el-button
                        type="danger"
                        size="mini"
                        plain
                        @click="refund('', scope.row)"
                        v-showFunction="{
                          active: 'refund',
                          array: allFunctionButtons,
                        }"
                        >退款</el-button
                      >
                    </div>
                    <div v-else-if="itd.type == 'delete'">
                      <el-button
                        type="danger"
                        size="mini"
                        icon="el-icon-delete"
                        circle
                        plain
                        v-showFunction="{
                          active: 'delete',
                          array: allFunctionButtons,
                        }"
                        @click="drawerOperFile('', scope.row, scope.row.id)"
                      ></el-button>
                    </div>
                    <div
                      v-else-if="itd.type == 'invoiceType'"
                      :style="{
                        color: scope.row.color,
                        fontWeight: scope.row.weight,
                      }"
                    >
                      <i
                        v-if="scope.row.isPush"
                        class="el-icon-s-promotion"
                      ></i>
                      {{ scope.row[itd.key] }}
                    </div>
                    <div v-else>
                      {{ scope.row[itd.key] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'table-lazy' && it.isShow"
            >
              <div
                class="my-drawer-content-model-line-value-column-table-title"
                v-if="it.tableTitle"
              >
                {{ it.tableTitle }}
              </div>
              <el-table
                ref="tableData"
                :data="it.tableData"
                style="width: 100%"
                max-height="720"
                :show-summary="it.summary"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  v-if="data.title == '发票'"
                  type="selection"
                  width="35"
                >
                </el-table-column>
                <el-table-column
                  :prop="itd.key"
                  :label="itd.value"
                  v-for="(itd, i) in it.menu"
                  :key="i"
                  show-overflow-tooltip
                  :width="itd.type == 'buts' ? 180 : itd.width"
                  :fixed="itd.key == 'contractNumber'"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="itd.type == 'link'"
                      style="color: #7db1fe; cursor: pointer"
                      @click="linkNow(scope.row, ii)"
                    >
                      <i
                        v-if="
                          scope.row.isUrgent &&
                            (scope.row.status == '待审核' ||
                              scope.row.status == '待提交')
                        "
                        class="iconfont icon-shandianjiaji"
                      ></i>
                      {{ scope.row[itd.key] }}
                    </div>

                    <div v-else-if="itd.type == 'array'" style="display: flex">
                      <div
                        style="margin: 0 2px; cursor: pointer"
                        v-for="ddd in scope.row[itd.key]"
                        :key="ddd.text"
                      >
                        <el-tooltip
                          :content="ddd.tip"
                          placement="bottom"
                          effect="light"
                        >
                          <div
                            class="table-tip"
                            :style="{
                              borderColor: ddd.color,
                              color: ddd.color,
                            }"
                          >
                            {{ ddd.text }}
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                    <div
                      v-else-if="itd.type == 'text-status'"
                      :style="{ color: scope.row.color }"
                    >
                      {{ scope.row.status }}
                    </div>
                    <div
                      v-else-if="itd.type == 'file'"
                      style="color: #7db1fe; cursor: pointer; font-size: 11px"
                      @click="linkNowFile(scope.row, ii)"
                    >
                      查看附件
                    </div>
                    <div
                      v-else-if="itd.type == 'operating'"
                      style="color: #7db1fe; cursor: pointer; font-size: 11px"
                      @click="linkNowOperating(scope.row, ii)"
                    >
                      查看记录
                    </div>
                    <div v-else-if="itd.type == 'buts'">
                      <template v-for="(but, butIndex) in itd.buts">
                        <el-button
                          :key="butIndex"
                          type="primary"
                          size="mini"
                          :icon="but.icon"
                          @click="
                            drawerOperFile(but.active, scope.row, scope.row.id)
                          "
                          >{{ but.name }}</el-button
                        >
                      </template>
                    </div>
                    <div v-else-if="itd.type == 'buts-dropdown'">
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                          操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="(but, butIndex) in itd.buts">
                            <el-dropdown-item
                              :key="butIndex"
                              v-if="matchStatus(scope.row, but.active)"
                              @click.native="
                                drawerOperArchives(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                              >{{ but.name }}</el-dropdown-item
                            >
                          </template>
                          <el-dropdown-item
                            v-showFunction="{
                              active: 'deleteArchives',
                              array: allFunctionButtons,
                            }"
                            @click.native="
                              drawerOperArchives(
                                'delete',
                                scope.row,
                                scope.row.id
                              )
                            "
                            >删除</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div v-else-if="itd.type == 'buts-dropdown-contract'">
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                          操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="(but, butIndex) in itd.buts">
                            <el-dropdown-item
                              :key="butIndex"
                              v-if="but.key == 'refund'"
                              @click.native="
                                drawerOperContract(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                            >
                              <span v-if="parseFloat(scope.row.money) > 0">{{
                                but.name
                              }}</span></el-dropdown-item
                            >

                            <el-dropdown-item
                              :key="butIndex"
                              v-else-if="but.key == 'editTime'"
                              @click.native="
                                drawerOperContract(
                                  but.active,
                                  scope.row,
                                  scope.row.id
                                )
                              "
                            >
                              <span v-if="whetherAgencyHead">{{
                                but.name
                              }}</span>
                            </el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div
                      v-else-if="
                        itd.type == 'refund' && parseFloat(scope.row.money) > 0
                      "
                    >
                      <el-button
                        type="danger"
                        size="mini"
                        plain
                        @click="refund('', scope.row)"
                        v-showFunction="{
                          active: 'refund',
                          array: allFunctionButtons,
                        }"
                        >退款</el-button
                      >
                    </div>
                    <div v-else-if="itd.type == 'delete'">
                      <el-button
                        type="danger"
                        size="mini"
                        icon="el-icon-delete"
                        circle
                        plain
                        v-showFunction="{
                          active: 'delete',
                          array: allFunctionButtons,
                        }"
                        @click="drawerOperFile('', scope.row, scope.row.id)"
                      ></el-button>
                    </div>
                    <div
                      v-else-if="itd.type == 'invoiceType'"
                      :style="{
                        color: scope.row.color,
                        fontWeight: scope.row.weight,
                      }"
                    >
                      <i
                        v-if="scope.row.isPush"
                        class="el-icon-s-promotion"
                      ></i>
                      {{ scope.row[itd.key] }}
                    </div>
                    <div v-else>
                      {{ scope.row[itd.key] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'radio' && it.isShow"
            >
              <el-radio-group
                v-model="it.value"
                :disabled="it.isDisable"
                @change="saveDrawerData(it.title)"
              >
                <el-radio
                  :label="ddd.label"
                  v-for="ddd in it.options"
                  :key="ddd.label"
                  >{{ ddd.text }}</el-radio
                >
              </el-radio-group>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'radioChange' && it.isShow"
            >
              <el-radio-group
                v-model="it.value"
                :disabled="it.isDisable"
                @change="radioChange(it.value, it)"
              >
                <el-radio
                  :label="ddd.label"
                  v-for="ddd in it.options"
                  :key="ddd.label"
                  >{{ ddd.text }}</el-radio
                >
              </el-radio-group>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'htservice' && it.isShow"
            >
              <!-- 服务项 -->
              <div
                class="
                  my-drawer-content-model-line-value-column-htservice-title
                "
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-title
                  "
                >
                  {{ it.tabletext }}({{ it.value.length }})
                </div>
                <!-- <div
                  class="my-drawer-content-model-line-value-column-htservice-title-but"
                  v-for="(dd, iii) in it.buttons"
                  :key="iii"
                  @click="drawerOper(dd.active)"
                  v-show="dd.authority == 1"
                >
                  {{ dd.text }}
                </div> -->
              </div>
              <div
                class="my-drawer-content-model-line-value-column-htservice-list"
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-item
                  "
                  v-for="(itt, iii) in it.value"
                  :key="iii"
                >
                  <!-- 详情 -->
                  <service_details
                    v-if="itt.flag"
                    :serviceDetailsData="itt.item"
                    @show="showServiceDetailsData"
                    @but_click_sure="but_click_sure(index, iii)"
                    @but_click_canel="but_click_canel(index, iii)"
                  ></service_details>
                  <!-- 表格 -->
                  <service_item
                    v-else
                    :serviceItemData="itt.tableData"
                    @show="showServiceItemData"
                    @editItem="editItem(index, iii)"
                  ></service_item>
                </div>
              </div>
              <!-- <div
                v-if="it.value.length > 0"
                class="my-drawer-content-model-line-value-column-htservice-sum"
              >
                合同金额:{{ it.contactMoney }}元
              </div> -->
              <div
                v-if="it.value.length == 0"
                class="my-drawer-content-model-line-value-column-htservice-tip"
              >
                尚未添加服务项
              </div>
            </div>

            <!-- 产品数量 -->
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'text-proNum' && it.isShow && it.value !== 'a'"
            >
              <div
                class="
                  my-drawer-content-model-line-value-column-htservice-title
                "
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-title
                  "
                >
                  {{ it.tabletext }}({{ it.value.length }})
                </div>
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-but
                  "
                  v-for="(dd, iii) in it.buttons"
                  :key="iii"
                  @click="showSearch"
                  v-showFunction="{
                    active: dd.key,
                    array: allFunctionButtons,
                  }"
                >
                  {{ dd.text }}
                </div>
              </div>
              <div
                class="my-drawer-content-model-line"
                v-for="(itt, iii) in it.value"
                :key="iii"
              >
                <div class="my-drawer-content-model-line-key">
                  产品名称{{ iii + 1 }}
                </div>

                <div class="my-drawer-content-model-line-value">
                  <el-input
                    v-model="it.value[iii]"
                    :disabled="it.isDisable"
                    placeholder="请输入产品名称"
                    @change="saveTag(it.value[iii], ii, index)"
                  ></el-input>
                </div>

                <div class="my-drawer-content-model-line-value-icon">
                  <i
                    class="el-icon-delete"
                    @click="deleteTag(iii, ii, index)"
                  ></i>
                </div>
              </div>
              <div v-if="inputVisible">
                <el-input
                  v-focus
                  v-model="it.option"
                  placeholder="请输入产品标签"
                  @blur="inputVisible = false"
                  @keyup.enter.native="enterTag(it.option, ii, index)"
                  autofocus
                ></el-input>
              </div>
            </div>

            <!-- 赠送服务 -->
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'give-service-item' && it.isShow"
            >
              <!-- 赠送的服务项 -->
              <div
                class="
                  my-drawer-content-model-line-value-column-htservice-title
                "
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-title
                  "
                >
                  {{ it.tabletext }}({{ it.value.length }})
                </div>
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-but
                  "
                  v-for="(dd, iii) in it.buttons"
                  :key="iii"
                  @click="drawerOper(dd.active)"
                  v-showFunction="{
                    active: dd.key,
                    array: allFunctionButtons,
                  }"
                >
                  {{ dd.text }}
                </div>
              </div>

              <div
                class="my-drawer-content-model-line-value-column-htservice-list"
                v-if="it.value.length > 0"
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-item
                  "
                  v-for="(itt, iii) in it.value"
                  :key="iii"
                >
                  <GiveServiceItem
                    :giveServiceItem="itt"
                    :isShow="it.isShow"
                    :isDisable="it.isDisable"
                    @savePresentServiceItems="saveDrawerData(it.title)"
                    @cancelServiceItems="cancelServiceItems"
                    @deleteService="deleteService(iii)"
                  ></GiveServiceItem>
                </div>
              </div>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'advances_received' && it.isShow"
            >
              <!-- 预收款 -->

              <div
                class="
                  my-drawer-content-model-line-value-column-htservice-title
                "
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-title
                  "
                >
                  {{ it.tabletext }}
                </div>
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-title-but
                  "
                  v-for="(dd, iii) in it.buttons"
                  :key="iii"
                >
                  {{ dd.text }}
                </div>
              </div>
              <div
                class="my-drawer-content-model-line-value-column-htservice-list"
              >
                <div
                  class="
                    my-drawer-content-model-line-value-column-htservice-item
                  "
                  v-for="(itt, iii) in it.value"
                  :key="iii"
                >
                  <!-- 表格 -->
                  <advances_item
                    :advancesItemData="itt.tableData"
                  ></advances_item>
                </div>
              </div>
              <div
                v-if="it.value.length > 0"
                class="my-drawer-content-model-line-value-column-htservice-sum"
              >
                总预收金额:{{ it.contactMoney }}元
              </div>
              <div
                v-else
                class="my-drawer-content-model-line-value-column-htservice-tip"
              >
                尚未添加预收款
              </div>
            </div>
            <!-- 项目步骤 -->
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'step' && it.isShow"
            >
              <!-- 步骤 -->
              <step
                :stepData="it.value"
                @editStep="editStep"
                @addStep="addStep"
                @doneStep="doneStep"
                @deleteStep="deleteStep"
                @detailStep="detailStep"
                @workOrder="workOrder"
              ></step>
            </div>
            <!-- 项目记录 -->
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'projectRecord' && it.isShow"
            >
              <projectRecord
                @addProjectRecord="addProjectRecord"
                :projectRecordData="it.value"
              ></projectRecord>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'expend' && it.isShow"
            >
              <!-- 支出情况表 -->
              <expend_item
                :expendData="it.value"
                @showExpendDetails="showExpendDetails"
                @addExpend="addExpend"
              ></expend_item>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'fujian' && it.isShow"
            >
              <!-- 合同附件表 -->
              <ContractInAttachment
                :attachmentData="it.value"
                @showAttachment="showAttachment"
                @saveDrawerData="saveDrawerData(it.title)"
              ></ContractInAttachment>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'cloud' && it.isShow"
            >
              <div></div>
              <div
                class="my-drawer-content-model-line-value-column-table-title"
                v-if="it.tableTitle"
              >
                {{ it.tableTitle }}
              </div>
              <el-table
                :data="it.tableData"
                style="width: 100%"
                max-height="720"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="35"></el-table-column>
                <el-table-column
                  :prop="itd.key"
                  :label="itd.value"
                  v-for="(itd, i) in it.menu"
                  :key="i"
                  show-overflow-tooltip
                  :width="itd.width"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="itd.type == 'image'"
                      v-viewer="{ movable: false }"
                    >
                      <i
                        v-if="scope.row.type == 0"
                        class="iconfont icon-folder"
                      ></i>
                      <img
                        v-else-if="
                          scope.row.fileType == 'jpg' ||
                            scope.row.fileType == 'png'
                        "
                        style="width: 25px; height: 25px"
                        :src="scope.row.uri"
                      />
                      <img
                        v-else-if="
                          scope.row.fileType == 'doc' ||
                            scope.row.fileType == 'docx'
                        "
                        style="width: 25px; height: 25px"
                        src="../assets/img/word.png"
                      />
                      <img
                        v-else-if="
                          scope.row.fileType == 'xlsx' ||
                            scope.row.fileType == 'xls'
                        "
                        style="width: 25px; height: 25px"
                        src="../assets/img/excel.png"
                      />
                      <img
                        v-else-if="scope.row.fileType == 'pdf'"
                        style="width: 25px; height: 25px"
                        src="../assets/img/pdf.png"
                      />
                    </div>
                    <div
                      v-else-if="itd.type == 'link'"
                      style="color: #7db1fe; cursor: pointer"
                      @click="linkFile(scope.row)"
                    >
                      {{ scope.row[itd.key] }}
                    </div>
                    <div
                      v-else-if="itd.type == 'downLoad' && scope.row.type == 1"
                      style="color: '#ECF5FF'; cursor: pointer;"
                    >
                      <i class="el-icon-download"></i>
                    </div>
                    <div v-else-if="itd.type == 'switch'">
                      <el-switch
                        v-model="scope.row[itd.key]"
                        active-color="#13ce66"
                        :active-value="1"
                        :inactive-value="0"
                        :disabled="disabledFlag(itd.authorityKey)"
                        @change="visibleFile($event, scope.row)"
                      >
                      </el-switch>
                    </div>
                    <div v-else>
                      {{ scope.row[itd.key] || "--" }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'invoice-register' && it.isShow"
            >
              <invoice_register
                :invoiceRegisterDrawerData="it.value"
                @save="saveDrawerData(it.title)"
              ></invoice_register>
            </div>

            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'create_thread' && it.isShow"
            >
              <CreateThreadRetrieve
                :createThreadRetrieveData="it.value"
                @changeOrCreateThread="changeOrCreateThread"
                @searchChangeThread="searchChangeThread"
                @claimsFor="claimsFor"
              ></CreateThreadRetrieve>
            </div>

            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'create_resource' && it.isShow"
            >
              <!-- 创建资源 -->
              <CreateResourceRetrieve
                :createResourceRetrieveData="it.value"
                @changeOrCreateResource="changeOrCreateResource"
              ></CreateResourceRetrieve>
            </div>

            <div
              class="my-drawer-content-model-line-value"
              v-if="it.type == 'file' && it.isShow"
            >
              {{ it.value }}
            </div>

            <div
              class="my-drawer-content-model-line-value-column"
              v-if="it.type == 'operation' && it.isShow"
            >
              <!-- 操作记录-->
              <div
                class="my-drawer-content-model-line-value-column-operation"
                v-for="(tt, ti) in it.value"
                :key="ti"
              >
                <div class="operation-icon">
                  <img
                    :src="tt.icon"
                    alt=""
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="operation-content">
                  <div class="operation-content-line">{{ tt.userName }}</div>
                  <div class="operation-content-line">{{ tt.operation }}</div>
                </div>
                <div class="operation-time">{{ tt.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { pca, pcaa } from "area-data"; // 城市数据
import pcaa from "../assets/util/pcaa0.js";
import "vue-area-linkage/dist/index.css"; // 样式
import service_item from "./serviceItem.vue";
import service_details from "./service-details.vue";
import advances_item from "./advancesItem.vue";
import advances_details from "./advancesDetails.vue";
import GiveServiceItem from "./give-service-item.vue";
import expend_item from "./expend.vue";
import step from "./step.vue";
import invoice_register from "./invoice-register.vue";
import ContractInAttachment from "./contract-in-attachment.vue";
import CreateThreadRetrieve from "./create-thread-retrieve.vue";
import CreateResourceRetrieve from "./create-resource-retrieve.vue";
import projectRecord from "./project-record.vue";
export default {
  components: {
    service_item,
    service_details,
    advances_item,
    advances_details,
    expend_item,
    step,
    invoice_register,
    GiveServiceItem,
    ContractInAttachment,
    CreateThreadRetrieve,
    CreateResourceRetrieve,
    projectRecord,
  },
  // props: ["drawerData"],
  props: {
    allFunctionButtons: {
      type: Array,
      default: function() {
        return [];
      },
    },
    drawerData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    model: {
      type: String,
      default: function() {
        return "";
      },
    },
    minWidth: {
      type: String,
      default: "500px",
    },
  },
  data() {
    return {
      data: this.drawerData,
      turemodel: this.model,
      pcaa: pcaa,
      index: 0,
      // 输入框展示
      inputVisible: false,
      whetherAgencyHead: false,
    };
  },
  watch: {
    drawerData() {
      //父组件值变化->>子组件也变化
      this.data = this.drawerData;
    },
    data(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
    model() {
      //父组件值变化->>子组件也变化
      this.turemodel = this.model;
    },
  },
  computed: {
    isLastItem() {
      return (index) => index === this.data.data.fileOrder.length - 1;
    },
  },
  methods: {
    disabledFlag(authorityKey) {
      let flag = true;
      this.allFunctionButtons.forEach((item) => {
        if (item.key == authorityKey && item.authority == "1") {
          flag = false;
        }
      });
      return flag;
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
      });
    },
    closeUpdateTableDrawer() {
      this.$emit("closeUpdateTableDrawer");
    },
    saveDrawerData(title) {
      this.$emit("saveDrawerData", title);
    },
    updateDrawerTitle(title) {
      this.$emit("updateDrawerTitle", title);
    },
    enter() {
      this.$emit("enter");
    },
    // 带输入建议的input框
    querySearch(queryString, cb) {
      var restaurants = this.data.data.list;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleClose(tag, j, i) {
      this.$emit("tagsHandleClose", { tag, i, j });
    },
    addTags(i, j) {
      this.$emit("addTags", { i, j });
    },
    drawerOper(active) {
      this.$emit("drawerOper", active);
    },
    // 云盘 tip 目录点击
    tipChange(data) {
      this.$emit("tipChange", data);
    },
    changeTags(flag, i, j) {
      flag = true;
    },
    newButton(i, j) {
      this.$emit("newButton", { i, j });
    },
    lineOper(active, i) {
      this.$emit("drawerOper", active);
    },
    lineOpers(active, i) {
      this.$emit("lineOpers", { active, i });
    },
    showServiceItemData(data) {},
    showServiceDetailsData(data) {},
    editItem(i, j) {
      if (!this.data.data.modelList[i].line[0].isDisable) {
        this.data.data.modelList[i].line[0].value[j].flag = true;
      } else {
        this.$message({
          message: "当前合同状态不可编辑!",
          duration: 1000,
          type: "error",
        });
      }
    },
    but_click_canel(i, j) {
      if (!this.data.data.modelList[i].line[0].value[j].tableData.name) {
        this.data.data.modelList[i].line[0].value.splice(j, 1);
        return;
      }
      this.data.data.modelList[i].line[0].value[j].flag = false;
    },
    but_click_sure(i, j) {
      this.data.data.modelList[i].line[0].value[
        j
      ].tableData.cycle = this.data.data.modelList[i].line[0].value[
        j
      ].item[1].value;

      this.data.data.modelList[i].line[0].value[j].tableData.name = JSON.parse(
        JSON.stringify(
          this.data.data.modelList[i].line[0].value[j].item[0].value
        )
      );
      this.data.data.modelList[i].line[0].value[j].tableData.data = [];
      this.data.data.modelList[i].line[0].value[j].item[6].value.forEach(
        (element) => {
          let dd = {
            serviceTime: element.time1 + "-" + element.time2,
            price: element.price,
          };
          this.data.data.modelList[i].line[0].value[j].tableData.data.push(dd);
        }
      );
      this.data.data.modelList[i].line[0].value[j].flag = false;
      this.saveDrawerData();
    },
    showExpendDetails(data) {
      this.$emit("showExpendDetails", data);
    },
    addExpend() {
      this.$emit("addExpend");
    },
    editStep(data) {
      this.$emit("editStep", data);
    },
    addStep(data) {
      this.$emit("addStep", data);
    },
    doneStep(data) {
      this.$emit("doneStep", data);
    },
    deleteStep(data) {
      this.$emit("deleteStep", data);
    },
    detailStep(data) {
      this.$emit("detailStep", data);
    },
    workOrder(data) {
      this.$emit("workOrder", data);
    },
    addLinkman(linkman, data, i, j) {
      if (
        linkman.name == "" ||
        (linkman.phone[0] == "" && linkman.phone[1] == "")
      ) {
        this.$message.error("请将联系人信息填充完整!");
        return;
      }
      let dd = {
        name: "",
        phone: ["", ""],
      };
      data.value.push(dd);
    },
    cancelLinkman(linkman, data, i, j) {
      data.value.splice(data.value.indexOf(linkman), 1);
      this.saveDrawerData();
    },
    linkNow(item, index) {
      this.$emit("linkNow", { item, index });
    },
    linkNowFile(item, index) {
      this.$emit("linkNowFile", { item, index });
    },
    linkFile(item) {
      this.$emit("linkFile", { item });
    },
    visibleFile(e, item) {
      this.$emit("visibleFile", { ...item, clientAppShow: e });
    },
    linkNowOperating(item, index) {
      this.$emit("linkNowOperating", { item, index });
    },
    radioChange(val, item) {
      this.$emit("radioChange", { val, item });
    },
    /**
     * 取消赠送服务项修改
     */
    cancelServiceItems() {
      this.$emit("cancelServiceItems");
    },
    /**
     * 删除具体赠送的服务项
     */
    deleteService(index) {
      this.$emit("deleteService", index);
    },
    /**
     * 合同附件父子组件连锁事件
     */
    showAttachment(data) {
      this.$emit("showAttachment", data);
    },
    changeOrCreateThread() {
      this.$emit("changeOrCreateThread");
    },
    searchChangeThread() {
      this.$emit("searchChangeThread");
    },
    /**
     * 合同界面的客户链接
     */
    linkClitent() {
      this.$emit("linkClitent");
    },
    /**
     * 新建商机时检索商机的申领
     */
    claimsFor(id) {
      this.$emit("claimsFor", id);
    },
    /**
     * 查看关联客户
     */
    showRelevanceClient() {
      this.$emit("showRelevanceClient");
    },
    /**
     * 手动删除关联客户
     */
    deleteRelevanceClient(id) {
      this.$emit("deleteRelevanceClient", id);
    },
    /**
     * 手动添加关联客户
     */
    addRelevanceClient() {
      this.$emit("addRelevanceClient");
    },
    changeOrCreateResource(value) {
      this.$emit("changeOrCreateResource", value);
    },

    /**
     * 显示输入框
     */
    showSearch() {
      this.inputVisible = true;
    },

    /**
     * 回车确认添加
     */
    enterTag(value, j, i) {
      this.$emit("enterTag", { value, i, j });
      this.inputVisible = false;
    },

    deleteTag(iii, j, i) {
      this.$emit("deleteTag", { iii, i, j });
    },

    /**
     * 保存标签
     */
    saveTag(iii, j, i) {
      this.$emit("saveTag", { iii, i, j });
    },
    /**
     * 按钮操作
     */
    drawerOperFile(active, row, id) {
      this.$emit("delete", { active, row, id });
    },
    refund(active, row) {
      this.$emit("refund", { active, row });
    },
    /**
     * 档案操作
     */
    drawerOperArchives(active, row, id) {
      this.$emit("archives", { active, row, id });
    },
    /**
     * 合同操作
     */
    drawerOperContract(active, row, id) {
      this.$emit("contract", { active, row, id });
    },
    /**
     * 表格可选
     */
    handleSelectionChange(val) {
      this.data.multipleSelection = val;
      // 如果选中表格项 显示 上传文件 新建文件夹按钮  否则 显示重命名, 移动, 删除, 下载按钮
      let flag = val.length > 0 ? true : false;
      this.data.data.topButArray.forEach((item, index) => {
        if (index == 0 || index == 1) {
          item.isDisable = flag;
        } else {
          item.isDisable = !flag;
        }
      });
    },
    matchStatus(row, active) {
      switch (row.status) {
        case "未领用":
          if (active == "get" || active == "move" || active == "jiaofu") {
            return true;
          }
          break;
        case "已领用":
          if (
            (active == "return" || active == "jiaofu") &&
            row.recipient == this.baseInfo.userId
          ) {
            return true;
          }
          break;
        case "已交付":
          break;
        default:
          break;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已交付") {
        return "warning-row";
      }
      return "";
    },
    callNumber(phone) {
      this.$emit("callNumber", phone);
    },
    onCopy(key, title, phone) {
      if (
        (this.data.title == "客户" || this.data.title == "销售商机") &&
        (key == "phone" || key == "contact")
      ) {
        this.$emit("copy", title, phone);
      }
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file);
      let isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过!");
      }
      return isLt2M;
    },
    handleAvatarSuccess(response, file, fileList) {
      console.log("file", file);
      let dd = {
        mf: file.raw,
        name: file.name,
      };
      let fileUrlList = file.name.split(".");
      let imgType = ["jpg", "jpeg", "png"];
      if (imgType.indexOf(fileUrlList[fileUrlList.length - 1]) >= 0) {
        dd.type = "img";
      } else {
        dd.type = "text";
      }
      this.$emit("drawerOper", "uploadFile", dd);
    },
    addProjectRecord(textarea) {
      this.$emit("addProjectRecord", textarea);
    },
    lazyLoading() {
      if (this.$refs.tableData) {
        let dom = this.$refs.tableData.bodyWrapper;
        dom.addEventListener("scroll", (v) => {
          console.log("111");
          const scrollDistance =
            dom.scrollHeight - dom.scrollTop - dom.clientHeight;
          console.log("鼠标滑动-scrollDistance", scrollDistance);
          // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        });
      }
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.isCharge();
    console.log("抽屉模块数据", this.drawerData);
    console.log("抽屉模块权限", this.allFunctionButtons);
    this.lazyLoading();
  },
};
</script>

<style lang="less" scoped>
#my_drawer {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
  .icon-folder {
    font-size: 30px;
  }
  /deep/.el-table .warning-row {
    background: #f5f7fa;
  }
  .my_drawer-active {
    flex: 1;
  }
  .my_drawer-content {
    // width: 700;
    min-width: 500px;
    height: 100%;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    transition: 0.3s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .my-drawer-title {
      width: 100%;
      height: 45px;
      display: flex;
      // position: fixed;
      border-bottom: 1px solid @boderColor;
      .my-drawer-title-text,
      .my-drawer-title-texts {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 15px;
      }
      .my-drawer-title-texts {
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        margin-left: 0;
        .my-drawer-title-texts-cards {
          flex: 1;
          display: flex;
          .my-drawer-title-texts-card {
            width: auto;
            margin: 0 10px;
            padding: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0);
          }
          .my-drawer-title-texts-card:hover {
            cursor: pointer;
            color: @activeColor;
          }
          .is-active {
            color: @activeColor;
            border-bottom: 1px solid @activeColor;
          }
        }
      }
      .my-drawer-title-opers {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 12px;
        .my-drawer-title-oper {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          i {
            margin-right: 2px;
          }
        }
        .my-drawer-title-oper:hover {
          cursor: pointer;
          color: @activeColor;
        }
      }
      .my-drawer-title-setting {
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #868686;
        border-left: 1px solid @boderColor;
        border-right: 1px solid @boderColor;
      }
      .my-drawer-title-setting:hover {
        cursor: pointer;
        color: @activeColor;
      }
      .my-drawer-title-close {
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .my-drawer-title-close:hover {
        color: @closeColor;
      }
    }
    .my-drawer-content {
      width: 98%;
      flex: 1;
      max-height: 900px;
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      // margin-top: 45px;
      margin-bottom: 40px;
      overflow-y: auto;
      align-items: flex-start;

      &::-webkit-scrollbar {
        width: 1px;
      }
      .my-drawer-content-title {
        width: 98%;
        height: 40px;
        margin: 10px;
        display: flex;
        align-items: center;
        color: rgb(143, 147, 150);
        .my-drawer-content-title-name {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 15px;
          margin-left: 5px;
        }
        .my-drawer-content-title-name-link {
          color: #41a3ff;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            // border-bottom: #41a3ff 1px solid;
            // padding-bottom: 3px;
          }
        }
        .my-drawer-content-title-but {
          min-width: 80px;
          height: 30px;
          width: auto;
          margin: 0 10px;
          padding: 0 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @activeColor;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
          border-radius: 5px;
        }
        .my-drawer-content-title-customer {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-size: @contentFontSize;
          .my-drawer-content-title-customer-text {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            color: #333;
            span {
              width: 100%;
              height: 100%;
              margin-right: 5px;
            }
            .my-drawer-content-title-customer-text-header {
              width: 100%;
              height: 100%;
              transition: 0.3s;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
          }
          .my-drawer-content-title-customer-show {
            width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor3;
            cursor: pointer;
          }
        }
      }
      .my-drawer-content-search {
        width: 657px;
        padding: 0 10px;
        .my-drawer-content-search-line {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          flex-wrap: wrap;

          .my-drawer-content-search-item {
            /deep/ .el-input__inner {
              border: 1px solid #dcdfe6;
              width: 280px !important;
            }
          }
        }
        .my-drawer-content-search-line-byClient {
          width: 657px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          flex-wrap: wrap;

          .my-drawer-content-search-item {
            /deep/ .el-input__inner {
              border: 1px solid #dcdfe6;
              width: 160px !important;
            }
          }
          .my-drawer-content-button-item {
            display: flex;

            .avatar-uploader {
              margin-right: 10px;
            }
          }
        }
      }
      .my-drawer-content-tips {
        width: 657px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;

        .my-drawer-content-tip {
          color: #409eff;
          cursor: pointer;
        }
        .last-tip {
          color: #000;
          cursor: none;
        }
      }

      .my-drawer-content-model {
        width: 657px;
        height: auto;
        border: 1px solid @boderColor;
        border-bottom: none;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        margin: 0 10px 10px 10px;
        .my-drawer-content-model-line {
          // width: 327px;
          width: 658px;
          height: auto;
          min-height: 40px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid @boderColor;
          box-sizing: border-box;
          font-size: @contentFontSize;
          .my-drawer-content-model-line-key-marked {
            width: 5px;
            height: 100%;
            color: #f00;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            font-size: 16px;
          }
          .my-drawer-content-model-line-key {
            width: 100px;
            color: rgb(155, 161, 167);
            .phone {
              color: red;
              transform: rotateY(180deg);
              cursor: pointer;
            }
          }
          .my-drawer-content-model-line-value-icon {
            width: 15px;
            height: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            margin-right: 10px;
          }
          .my-drawer-content-model-line-value {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 5px;

            line-height: 25px;
            text-align: left !important;
            .phone {
              color: red;
              cursor: pointer;
            }
            .my-drawer-content-model-line-value-text {
              width: auto;
              margin: 0 5px;
            }
            .my-drawer-content-model-line-value-but,
            .my-drawer-content-model-line-value-button {
              width: auto;
              max-width: 120px;
              min-width: 70px;
              height: 30px;
              border: 1px solid @activeColor;
              box-sizing: border-box;
              border-radius: 5px;
              color: @activeColor;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 4px;
              padding: 0 5px;
              transition: 0.3s;
            }
            .my-drawer-content-model-line-value-button {
              width: 90%;
            }
            .my-drawer-content-model-line-value-but:hover,
            .my-drawer-content-model-line-value-button:hover {
              cursor: pointer;
              background-color: @activeColor;
              color: #fff;
            }
          }
          .my-drawer-content-model-line-value-column {
            flex: 1;
            max-width: 658px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            .my-drawer-content-model-line-value-record {
              width: 100%;
              height: 90px;
              display: flex;
              border-bottom: 1px solid @boderColor;
              margin: 5px 0;
              font-size: 12px;
              .my-drawer-content-model-line-value-record-icon,
              .my-drawer-content-model-line-value-record-time {
                height: 100%;
                width: 120px;
                display: flex;
                justify-content: center;
              }
              .my-drawer-content-model-line-value-record-icon {
                width: 60px;
              }
              .my-drawer-content-model-line-value-record-cont {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 100%;
                .my-drawer-content-model-line-value-record-cont-n,
                .my-drawer-content-model-line-value-record-cont-l {
                  width: 100%;
                  margin-left: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  height: 20px;
                  color: #333;
                }
                .my-drawer-content-model-line-value-record-cont-l {
                  color: rgb(171, 179, 196);
                }
              }
            }
            .my-drawer-content-model-line-value-record:nth-last-child(1) {
              border-bottom: none;
              margin-bottom: 10px;
            }
            .my-drawer-content-model-line-value-column-htservice-title {
              width: 100%;
              height: 40px;
              border-bottom: 1px solid @boderColor;
              box-sizing: border-box;
              display: flex;
              font-size: 14px;
              align-items: center;
              .my-drawer-content-model-line-value-column-htservice-title-title {
                flex: 1;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;
              }
              .my-drawer-content-model-line-value-column-htservice-title-but {
                width: auto;
                height: 25px;
                border: 1px solid @activeColor;
                border-radius: 5px;
                color: @activeColor;
                margin: 0 5px;
                padding: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
              }
              .my-drawer-content-model-line-value-column-htservice-title-but:hover {
                cursor: pointer;
                background-color: #41a3ff;
                color: rgb(255, 255, 255);
              }
            }
            .my-drawer-content-model-line-value-column-htservice-list {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid @boderColor;
              box-sizing: border-box;
              .my-drawer-content-model-line-value-column-htservice-item {
                padding: 10px 5px;
              }
            }
            .my-drawer-content-model-line-value-column-htservice-sum {
              width: 95%;
              height: 40px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-left: 10px;
            }
            .my-drawer-content-model-line-value-column-htservice-tip {
              width: 100%;
              height: 40px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .my-drawer-content-model-line-value-column-table-title {
              width: 98%;
              height: 35px;
              display: flex;
              align-items: center;
              margin-left: 10px;
            }
            .my-drawer-content-model-line-value-column-operation {
              width: 100%;
              height: auto;
              display: flex;
              border-bottom: 1px solid @boderColor;
              box-sizing: border-box;
              margin-top: 5px;
              &:nth-last-child(1) {
                border: none;
              }
              .operation-icon {
                width: 50px;
                height: 40px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding: 5px;
                box-sizing: border-box;
              }
              .operation-content {
                width: 0;
                height: auto;
                flex: 1;
                display: flex;
                flex-direction: column;
                .operation-content-line {
                  width: 100%;
                  height: 25px;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  color: #9ba1a7;
                  &:nth-child(1) {
                    color: #363636;
                  }
                }
              }
              .operation-time {
                width: 80px;
                height: 40px;
                display: flex;
                align-items: flex-start;
                padding: 5px;
                box-sizing: border-box;
                font-size: 12px;
              }
            }
          }
        }
        .my-drawer-content-model-line-long {
          width: 658px;
        }
      }
      .div-wrap {
        flex-wrap: wrap;
      }
      .dvi-padding2 {
        padding: 2px;
        box-sizing: border-box;
      }
      .div-center {
        align-items: center;
        justify-content: center;
      }

      .el-tag + .el-tag {
        margin-left: 10px;
        margin-top: 2px;
      }
      .button-new-tag {
        margin-left: 10px;
        margin-top: 2px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .hr {
    display: flex;
    flex-direction: column;
    .hr-value {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0;
      .hr-value-title {
        height: 30px;
        width: 0;
        display: flex;
        // justify-content: space-between;
        &:nth-child(1) {
          flex: 2;
        }
        &:nth-child(2) {
          flex: 3;
        }
        .hr-value-span {
          width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #9ba1a7;
        }
        .hr-value-va {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          /deep/ .el-input__suffix-inner {
            color: #f56c6c;
            font-size: 15px;
          }
        }
        .hr-value-icon {
          width: 30px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          transition: 0.3s;
          // color: #333;
          &:hover {
            cursor: pointer;
            color: @activeColor;
          }
        }
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
