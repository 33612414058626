<template>
  <div id="knowledge_college">
    <!-- 企学院 -->
    <div class="knowledge-college-screen">
      <!-- <div class="knowledge-college-screen-search">
        <el-select
          v-model="searchData.type"
          placeholder="请选择类型"
          @change="resetInitTableData"
          filterable
          clearable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="knowledge-college-screen-search">
        <el-date-picker
          v-model="searchData.time"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
          @change="resetInitTableData"
        >
        </el-date-picker>
      </div> -->
      <div class="knowledge-college-screen-search">
        <el-input
          placeholder="标题/内容/摘要"
          v-model="searchData.keyword"
          @keyup.enter.native="resetInitTableData"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetInitTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="knowledge-college-screen-active"></div>
      <div
        class="knowledge-college-screen-but-select"
        v-showFunction="{ active: 'add', array: allFunctionButtons }"
        @click="addKnowledge"
      >
        新增企学院信息
      </div>
    </div>
    <div class="knowledge-college-table">
      <div class="knowledge-college-table-card">
        <div class="knowledge-college-table-card-content">
          <div class="knowledge-college-table-card-content-left">
            <el-tree
              :props="props"
              :load="initTableData"
              lazy
              node-key="id"
              :data="tagList"
              :default-expanded-keys="activeId"
            >
              <template #default="{ data, node }">
                <el-row
                  style="width: 100%; height: 100%"
                  type="flex"
                  align="middle"
                  justify="space-between"
                  @click.native="nodeClick(data, node)"
                >
                  <el-col :span="21" style="text-align: left;">
                    <div class="el-tree-title">
                      <span style="margin-right: 5px"
                        ><i
                          class="el-icon-folder-opened"
                          v-if="node.level === 1"
                        ></i>
                        <i class="el-icon-document" v-if="node.level === 3"></i
                      ></span>
                      <span class="name"> {{ data.name }}</span>
                    </div>
                  </el-col>
                  <el-col :span="3" v-if="node.level === 3">
                    <i
                      class="el-icon-edit"
                      style="color: #409EFF; cursor: pointer; margin-right: 10px"
                      v-showFunction="{
                        active: 'edit',
                        array: allFunctionButtons,
                      }"
                      @click.stop="handleClick(data, 'edit')"
                    ></i>
                    <i
                      class="el-icon-delete"
                      style="color: #F56C6C"
                      v-showFunction="{
                        active: 'delete',
                        array: allFunctionButtons,
                      }"
                      @click.stop="handleClick(data, 'delete')"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </el-tree>
          </div>
          <div class="knowledge-college-table-card-content-right">
            <div class="right-line question">
              <div class="icon">
                <span>Q</span>
              </div>
              <div class="content">
                <div class="title">{{ tempData.title }}</div>
                <div class="time" v-if="tempData.uploadName && tempData.time">
                  创建人: {{ tempData.uploadName }} 创建时间:
                  {{ tempData.time }}
                </div>
              </div>
            </div>
            <div class="right-line answer">
              <div class="icon">
                <span>A</span>
              </div>
              <div class="content">
                <!-- 文字 -->
                <div v-html="tempData.additional"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as knowledgeData from "@/assets/js/knowledgeData.js";
export default {
  components: {
    my_dialog,
  },

  data() {
    return {
      baseInfo: {},
      loading: true,
      searchData: {
        keyword: "", // 类型名称
        type: "",
        search: "", // 搜索内容/标题
        time: [], // 时间段
        departTreeData: [],
        categoryList: [],
      },
      tagList: [],
      typeList: [
        {
          label: "文字",
          value: "0",
        },
        {
          label: "图片",
          value: "1",
        },
        {
          label: "文档",
          value: "2",
        },
        {
          label: "链接",
          value: "3",
        },
      ],
      tabindex: "0",
      tableData: [],
      dialogData: {
        flag: false,
        width: "35%",
        title: "新建",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      buttonAuth: [],
      multipleSelection: [], // 多选框
      tempData: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeCollege"
      ),
      props: {
        label: "name",
        isLeaf: "leaf",
      },
      editData: {},
      node: {},
      resolve: {},
      wordImg: require("@/assets/img/word.png"),
      excelImg: require("@/assets/img/excel.png"),
      pdfImg: require("@/assets/img/pdf.png"),
      activeId: "",
    };
  },
  methods: {
    /**
     * 获取界面的按钮权限
     */
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeCollege",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.getDepartmentTreeByUserId();
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getTreeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getTreeList() {
      let requestData = {
        flag: true,
      };
      this.$request(
        "post",
        "/enterpriseCollegeCategory/getPureTreeList",
        requestData,
        (data) => {
          if (data.length > 0) {
            this.searchData.categoryList = data.map((person) => {
              let { name, ...rest } = person; // 解构赋值，提取 name
              return {
                label: name, // 改为 label
                ...rest,
                children: person.children
                  ? person.children.map((child) => {
                      let { name, id } = child; // 对孩子也进行同样的操作
                      return {
                        label: name, // 改为 label
                        id: id,
                      };
                    })
                  : [], // 处理没有 children 的情况
              };
            });
          }
        }
      );
    },
    /**
     * 初始化表格
     */
    initTableData(node, resolve) {
      console.log("node.level", node.level);
      this.node = node;
      this.resolve = resolve;
      let requestData = {};
      switch (node.level) {
        case 0:
          // 一级
          this.tagList = [];
          this.activeId = [];
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            flag: true,
            keyword: this.searchData.keyword, // 模糊关键词匹配
          };
          this.$request(
            "post",
            "/enterpriseCollegeCategory/getTreeList",
            requestData,
            (data) => {
              if (data.length > 0) {
                this.tagList = data;
                // 收缩项有值 内容全部展开
                if (this.searchData.keyword) {
                  this.tagList.forEach((item) => {
                    this.activeId.push(item.id);
                    item.children.forEach((child) => {
                      this.activeId.push(child.id);
                    });
                  });
                }
              }
              return resolve(this.tagList);
            }
          );
          break;
        case 1:
          // 二级
          return resolve(node.data.children);
          break;
        case 2:
          // 三级
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            categoryId: node.data.id,
            keyword: this.searchData.keyword, // 模糊关键词匹配
          };
          this.$request(
            "post",
            "/enterpriseCollege/queryByCategory",
            requestData,
            (data) => {
              if (data.length > 0) {
                data.forEach((item) => {
                  item.leaf = true;
                });
              }
              return resolve(data);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    refreshInitTableData() {
      this.node.childNode = [];
      this.initTableData(this.node, this.resolve);
    },
    resetInitTableData() {
      this.node.childNode = [];
      this.initTableData({ level: 0 }, this.resolve);
    },
    nodeClick(data, node) {
      if (node.level === 3) {
        this.$request(
          "get",
          "/enterpriseCollege/getById?id=" + data.id,
          {},
          (data) => {
            console.log("更新数据", data);
            this.tempData = data;
            console.log("更新数据2", this.tempData);
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 表格按钮点击事件
     */
    handleClick(item, active) {
      this.editData = item;
      let requestData = {};
      switch (active) {
        case "edit":
          // 编辑
          this.$request(
            "get",
            "/enterpriseCollege/getById?id=" + this.editData.id,
            {},
            (data) => {
              this.dialogData.width = "1000px";
              this.dialogData.title = "编辑企学院信息";
              this.dialogData.buttonArray = [
                {
                  text: "提交",
                  active: "submit_edit",
                },
                { text: "取消", active: "cancel" },
              ];

              this.dialogData.data = knowledgeData.addKnowledgeDialogData(
                this.searchData.categoryList,
                this.searchData.departTreeData
              );
              this.dialogData.data[0].value.isDisable = false;
              this.dialogData.data[0].value.additional = data.additional;
              this.dialogData.data[0].value.departmentIds = JSON.parse(
                data.departmentIds
              );
              this.dialogData.data[0].value.categoryId = data.categoryId;
              this.dialogData.data[0].value.title = data.title;
              this.dialogData.data[0].value.content = data.content;
              this.dialogData.data[0].value.type = data.type;

              this.dialogData.flag = true;
            }
          );
          break;
        case "delete":
          // 删除

          this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/enterpriseCollege/del?id=" + this.editData.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.refreshInitTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
    /**
     * 新增企学院
     */
    addKnowledge() {
      this.dialogData.width = "1000px";
      this.dialogData.title = "新建企学院信息";
      this.dialogData.buttonArray = [
        {
          text: "提交",
          active: "submit_add",
        },
        { text: "取消", active: "cancel" },
      ];
      this.dialogData.data = knowledgeData.addKnowledgeDialogData(
        this.searchData.categoryList,
        this.searchData.departTreeData
      );
      this.dialogData.data[0].value.isDisable = false;
      console.log(this.dialogData);
      this.dialogData.flag = true;
    },
    /**
     * dialog 按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let additional = "";
      let dialogData = this.dialogData.data[0].value;
      let categoryId = "";
      let this_ = this;
      switch (active) {
        case "submit_add":
          // 确定添加企学院信息
          // dialogData.imgAdditional.forEach((element) => {
          //   if (this.$keyType.keyType(element.url) != "Object") {
          //     let uu = {
          //       path: element.url,
          //       title: "企学院附件",
          //     };
          //     element.url = uu;
          //   }
          // });
          // additional =
          //   dialogData.type == "0"
          //     ? dialogData.textAdditional
          //     : dialogData.type == "1"
          //     ? dialogData.imgAdditional
          //     : dialogData.type == "2"
          //     ? dialogData.documentAdditional
          //     : dialogData.linkAdditional;
          requestData = {
            id: "", // 企学院id,空则新增,不空则修改
            companyId: this.baseInfo.company.companyId, // 公司id
            userId: this.baseInfo.userId, // 上传人id
            departmentIds: JSON.stringify(dialogData.departmentIds), // 可见部门
            categoryId: dialogData.categoryId, // 分组名称
            title: dialogData.title, // 标题
            content: dialogData.content, // 摘要
            type: dialogData.type, // 类型
            additional: dialogData.additional, // 附加内容,图片文档等
          };
          console.log("requestData", requestData);
          if (requestData.departmentIds.length == 0) {
            this.$message.error("请选择可见部门!");
            return;
          }
          if (!requestData.categoryId) {
            this.$message.error("请选择分组!");
            return;
          }
          this.$request(
            "post",
            "/enterpriseCollege/saveOrUpdate",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.refreshInitTableData();
            }
          );
          break;
        case "submit_edit":
          // 确定编辑企学院信息
          //   dialogData.imgAdditional.forEach((element) => {
          //     if (this.$keyType.keyType(element.url) != "Object") {
          //       let uu = {
          //         path: element.url,
          //         title: "企学院附件",
          //       };
          //       element.url = uu;
          //     }
          //   });
          //   additional =
          //     dialogData.type == "0"
          //       ? dialogData.textAdditional
          //       : dialogData.type == "1"
          //       ? dialogData.imgAdditional
          //       : dialogData.type == "2"
          //       ? dialogData.documentAdditional
          //       : dialogData.linkAdditional;
          requestData = {
            id: this.editData.id, // 企学院id,空则新增,不空则修改
            companyId: this.baseInfo.company.companyId, // 公司id
            userId: this.baseInfo.userId, // 上传人id
            departmentIds: JSON.stringify(dialogData.departmentIds), // 可见部门
            categoryId: dialogData.categoryId, // 分组名称
            title: dialogData.title, // 标题
            content: dialogData.content, // 摘要
            type: dialogData.type, // 类型
            additional: dialogData.additional, // 附加内容,图片文档等
          };
          if (requestData.departmentIds.length == 0) {
            this.$message.error("请选择可见部门!");
            return;
          }
          if (!requestData.categoryId) {
            this.$message.error("请选择分组!");
            return;
          }
          this.$request(
            "post",
            "/enterpriseCollege/saveOrUpdate",
            requestData,
            (data) => {
              this.$message.success("修改成功!");
              this.dialogData.flag = false;
              if (this.tempData.id == this.editData.id) {
                // 选择与修改相同 更新数据
                this.nodeClick(data, { level: 3 });
              }
              this.refreshInitTableData();
            }
          );
          break;
        case "copy":
          // 复制内容
          this.$copyText(dialogData.additional).then(
            function(e) {
              console.log("copy arguments e:", e);
              this_.$message({
                message: "复制成功!",
                type: "success",
              });
            },
            function(e) {
              console.log("copy arguments e:", e);
              this_.$message({
                message: "复制失败!",
                type: "error",
              });
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * dialog 关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    openDocument() {
      console.log("this.tempData", this.tempData);
      window.open(this.tempData.additional.url, "_blank");
    },
  },
  mounted() {
    this.loading = true;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#knowledge_college {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-college-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .knowledge-college-screen-fiftrate {
      width: 65px;
      min-width: 65px;
      height: 30px;
      border: 1px solid @boderColor;
      box-sizing: border-box;
      color: @boderActiveColor;
      font-size: @contentFontSize;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.3s;
      i {
        margin: 0 4px;
      }
    }
    .knowledge-college-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
    .knowledge-college-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 5px;
    }
    .knowledge-college-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .knowledge-college-screen-active {
      flex: 1;
    }
    .knowledge-college-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .knowledge-college-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .knowledge-college-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .knowledge-college-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .knowledge-college-screen-but {
      width: 100px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-college-screen-but:hover {
      cursor: pointer;
      background-color: @activeColor2;
    }
    .knowledge-college-screen-but-boder {
      width: 50px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-college-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .knowledge-college-table {
    flex: 1;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .knowledge-college-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .knowledge-college-table-card-active {
        flex: 1;
      }
      .knowledge-college-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .knowledge-college-table-card {
      width: 98%;
      height: 95%;
      max-height: 830px;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-college-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .knowledge-college-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .knowledge-college-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .knowledge-college-table-card-active {
          flex: 1;
        }
        .knowledge-college-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .knowledge-college-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;

        .knowledge-college-table-card-content-left {
          width: 30%;
          border-right: 1px solid @boderColor;
          overflow-y: auto;
          .el-tree-title {
            display: flex;
            align-items: center;
            .name {
              width: 250px;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              white-space: nowrap;
            }
          }
          &::-webkit-scrollbar {
            width: 1px;
          }
        }
        .knowledge-college-table-card-content-right {
          width: 70%;
          padding: 15px;
          box-sizing: border-box;
          .right-line {
            display: flex;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 15px;
            border-radius: 3px;
            white-space: pre-wrap;
            .icon {
              width: 80px;
              display: flex;
              justify-content: space-evenly;
            }
            .content {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              text-align: left;
              .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .time {
                color: #979494;
              }
              .imgs {
                display: flex;
                .img {
                  width: 100px;
                  height: 100px;
                  cursor: pointer;
                  margin-left: 10px;
                  margin-bottom: 10px;
                }
              }
              .documents {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .question {
            background: #ecf5ff;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #409eff;
            }
          }
          .answer {
            background: #f3f5f8;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #b6bcca;
            }
          }
        }
      }
      .knowledge-college-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
