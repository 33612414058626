<template>
  <div id="employee">
    <div class="screen">
      <div class="screen-line">
        <div class="employee-screen-search">
          <el-input
            placeholder="搜索姓名/手机号"
            v-model="searchData.input"
            @keyup.enter.native="getEmployeeListByCompany"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="getEmployeeListByCompany"
            ></el-button>
          </el-input>
        </div>
        <!-- <div class="employee-screen-checked">
        <el-checkbox
          v-model="searchData.checked1"
          @change="getEmployeeListByCompany"
          >机构负责人及管理员</el-checkbox
        >
      </div> -->
        <div class="employee-screen-checked">
          <el-checkbox
            v-model="searchData.checked2"
            @change="getEmployeeListByCompany"
            >含离职员工</el-checkbox
          >
        </div>
        <div class="employee-screen-active"></div>
        <div
          class="employee-screen-but"
          v-showFunction="{ active: 'add', array: allFunctionButtons }"
          @click="openDialog({}, 'create-employ')"
        >
          新建员工
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="employee-content">
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @row-click="rowClick"
          style="width: 100%"
          height="98%"
          v-loading="loading"
        >
          <el-table-column type="index" width="55" fixed> </el-table-column>
          <el-table-column property="userName" label="姓名" width="150">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :disabled="!scope.row.dimission"
                :content="'离职时间: ' + scope.row.dimission"
                placement="top-start"
              >
                <span>{{ scope.row.userName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column property="phone" label="手机号" width="120">
          </el-table-column>
          <el-table-column property="company" label="所属机构">
            <template slot-scope="scope">
              <div v-for="(it, ii) in scope.row.company" :key="ii">
                <span v-if="ii > 0">,</span> {{ it.companyName }}
              </div>
              <div v-if="scope.row.company.length == 0">---</div>
            </template>
          </el-table-column>
          <el-table-column property="department" label="所属部门">
            <template slot-scope="scope">
              <div v-for="(it, ii) in scope.row.department" :key="ii">
                <span v-if="ii > 0">,</span> {{ it.departmentName }}
              </div>
              <div v-if="scope.row.department.length == 0">---</div>
            </template>
          </el-table-column>
          <el-table-column property="hiredate" label="入职时间" width="150">
          </el-table-column>
          <el-table-column property="role" label="角色"> </el-table-column>
          <el-table-column property="position" label="职位" width="120">
          </el-table-column>
          <el-table-column
            property="wechatUserId"
            label="企业微信ID"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column property="callNumber" label="主叫号" width="120">
          </el-table-column>
          <el-table-column property="middleNumber" label="中间号" width="120">
          </el-table-column>
          <el-table-column
            property="role"
            label="操作"
            width="350"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="success"
                @click.native.stop="openDialog(scope.row, 'settingPhone')"
                size="mini"
                v-showFunction="{
                  active: 'settingPhone',
                  array: allFunctionButtons,
                }"
                >外呼设置</el-button
              >
              <el-button
                type="success"
                @click.native.stop="openDialog(scope.row, 'update')"
                size="mini"
                v-showFunction="{ active: 'edit', array: allFunctionButtons }"
                >修改信息</el-button
              >
              <el-button
                type="primary"
                @click.native.stop="openDialog(scope.row, 'bind')"
                size="mini"
                v-showFunction="{ active: 'bind', array: allFunctionButtons }"
                >绑定</el-button
              >
              <el-button
                type="success"
                @click.native.stop="openDialog(scope.row, 'updatepsw')"
                size="mini"
                v-showFunction="{
                  active: 'updatePsw',
                  array: allFunctionButtons,
                }"
                >修改密码</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div id="my-drawer" v-if="employeeDrawerData.flag">
      <my_drawer
        :drawerData="employeeDrawerData"
        :model="'employee'"
        :allFunctionButtons="allFunctionButtons"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @drawerOper="drawerOper"
      >
      </my_drawer>
    </div>

    <!-- dialog -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
        @launch="launch"
        @deleteItem="deleteItem"
      ></my_dialog>
    </div>

    <div id="my-dialog" v-if="treeDialogData.flag">
      <my_dialog
        :width="treeDialogData.width"
        :title="treeDialogData.title"
        :buttonArray="treeDialogData.buttonArray"
        :data="treeDialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_drawer from "../components/my-drawer.vue";
import my_dialog from "../components/dialog.vue";
import * as employeeData from "../assets/js/employeeData.js";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      isDisable: false,
      whetherAgencyHead: true,
      treeDialogData: {
        flag: false,
        width: "60%",
        title: "角色选择",
        buttonArray: [
          {
            text: "确认",
            active: "choseRoles",
          },
          {
            text: "取消",
            active: "cancelTree",
          },
        ],
        data: [],
      },
      searchData: {
        input: "",
        checked1: false,
        checked2: false,
      },
      employeeDrawerData: {},
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增员工",
        empolyeeId: "",
        company: [],
        buttonArray: [
          {
            text: "确认",
            active: "add_employee",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      tableData: [],
      empolyeeList: [],
      buttonAuth: {
        createBut: 1, // 新建机构按钮  1为读写  2为只读  3为不可见
        updateBut: 1, // 修改信息按钮
        deleteBut: 1, // 删除按钮
        departure: 1, // 离职并交接
      },
      tempEmployee: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons("employee"),
      drawerAuthorityData: [],
      drawerDataAuthorityList: [],
      departmentList: [],
      tempData: {},
    };
  },
  methods: {
    /**
     * 获取所有部门列表
     */
    getDepartmentList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTree",
        requestData,
        (data) => {
          this.departmentList = data.children;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "employee",
        this.allFunctionButtons,
        (data) => {
          console.log("当前权限:", data);
          this.allFunctionButtons = data;
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
      });
    },
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.empolyeeList = data;
          this.getEmployeeListByCompany();
        },
        (err) => {
          this.$message.error(err);
        }
      );
    },
    /**
     * 获取表格数据
     * state:是否包含离职员工
     */
    getEmployeeListByCompany() {
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        search: this.searchData.input,
        isDiMission: this.searchData.checked2, // 是否包含离职
      };
      this.$request(
        "post",
        "/user/getEmployeeList",
        requestData,
        (data) => {
          this.tableData = data;
          this.loading = false;
        },
        (errorMessage) => {
          this.Loading();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格行点击事件
     */
    rowClick(row, column, event) {
      this.tempEmployee = row;
      setTimeout(() => {
        this.$featureData.getAttributeData(
          "employee",
          false,
          employeeData.returnDrawerKeys(),
          (authorityData) => {
            this.drawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "employee",
              authorityData,
              (authorityList) => {
                this.drawerDataAuthorityList = authorityList;
                // this.showContractInfoByID();
                let requestData = {
                  userId: row.userId,
                  companyId: this.baseInfo.company.companyId,
                  authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                    this.drawerAuthorityData
                  ),
                };
                this.$request(
                  "post",
                  "/user/getEmployeeInformationById",
                  requestData,
                  (data) => {
                    // 3.将权限数据对应到界面
                    this.employeeDrawerData.flag = false;

                    this.employeeDrawerData = employeeData.employeeDrawerData();
                    this.employeeDrawerData.status = data.state;
                    // this.tempSign = data.data.sign;
                    let education = employeeData.formatRecordFormalSchool(
                      data.education
                    );
                    let department = "";
                    data.department.forEach((e) => {
                      if (department == "") {
                        department = e.departmentName;
                      } else {
                        department = department + "," + e.departmentName;
                      }
                    });
                    let role = "";
                    data.role.forEach((e) => {
                      if (role == "") {
                        role = e.roleName;
                      } else {
                        role = role + "," + e.roleName;
                      }
                    });
                    this.employeeDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                      this.employeeDrawerData,
                      data
                    );

                    for (
                      let index = 0;
                      index < this.employeeDrawerData.data.modelList.length;
                      index++
                    ) {
                      const element = this.employeeDrawerData.data.modelList[
                        index
                      ];
                      for (let i = 0; i < element.line.length; i++) {
                        const ele = element.line[i];
                        if (ele.key == "education") {
                          ele.value = education;
                        }
                        if (ele.key == "department") {
                          ele.value = department;
                        }
                        if (ele.key == "role") {
                          ele.value = role;
                        }
                      }
                    }
                    this.employeeDrawerData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              }
            );
          }
        );
      }, 0);

      // let requestData = {
      //   userId: row.userId,
      //   companyId: this.baseInfo.company.companyId,
      // };
      // this.$request(
      //   "post",
      //   "/user/getEmployeeInformationById",
      //   requestData,
      //   (data) => {
      //     let drawerData = this.employeeDrawerData.data.modelList[0].line;
      //     this.employeeDrawerData.data.title = row.userName;
      //     this.employeeDrawerData.settingOperList[0].authority =
      //       data.state == "离职" ? 3 : this.buttonAuth.departure; // 离职并交接
      //     this.employeeDrawerData.data.butArray[0].authority =
      //       data.state == "在职" ? 3 : this.buttonAuth.deleteBut; // 删除
      //     drawerData[0].value = data.userName;
      //     drawerData[1].value = data.phone;
      //     drawerData[2].value = data.phone;
      //     drawerData[3].value = data.sex;
      //     drawerData[4].value = data.identity;
      //     drawerData[5].value = "";

      //     drawerData[6].value = row.role;
      //     drawerData[7].value = employeeData.formatRecordFormalSchool(
      //       data.education
      //     );
      //     drawerData[8].value = data.state;
      //     drawerData[9].value = data.achievement;
      //     drawerData[10].value = data.hiredate;
      //     this.employeeDrawerData.flag = true;
      //   },
      //   (errorMessage) => {
      //     this.$message.error(errorMessage);
      //     this.dialogData.flag = false;
      //   }
      // );
    },
    /**
     * dialog打开
     */
    openDialog(data, active) {
      this.tempData = data;
      let requestData = {};
      let role = [];
      let company = [];
      switch (active) {
        case "create-employ":
          // 新建员工
          this.dialogData.width = "30%";
          this.dialogData.title = "新增员工";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "add_employee",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = employeeData.createDialogData(
            this.departmentList
          );
          this.dialogData.flag = true;
          break;
        case "update":
          // 员工基本信息修改
          this.dialogData.width = "30%";
          this.dialogData.title = "员工信息修改";
          this.dialogData.empolyeeId = data.userId;
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "update_employee",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          role = [];
          company = [];
          this.dialogData.data = employeeData.uodateDialogData();
          this.dialogData.company = company;
          console.log("员工信息:", data);
          this.dialogData.data[0].value = data.userName;
          this.dialogData.data[1].value = data.position;
          this.dialogData.data[2].value = data.phone;
          this.dialogData.data[3].value = data.hiredate;
          this.dialogData.data[4].value = data.identity;
          this.dialogData.data[5].value = data.state == "在职" ? 1 : 0;
          this.dialogData.data[6].value = data.education;
          this.dialogData.flag = true;
          break;
        case "delete":
          // 员工删除
          this.$confirm("此操作将永久删除该员工, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                userId: data.userId,
                companyId: this.baseInfo.company.companyId,
              };
              this.$request(
                "post",
                "/user/deleteEmployee",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getEmployeeListByCompany();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "bind":
          // 企业微信的绑定
          this.dialogData.width = "30%";
          this.dialogData.title = "企业微信的绑定";
          this.dialogData.empolyeeId = data.userId;
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "update_employee_wechat",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            { title: "姓名", type: "input_disable", value: data.userName },
            {
              title: "企业微信账号",
              type: "input",
              value: data.wechatUserId,
            },
            {
              title: "",
              type: "prompt",
              value:
                "每个成员都有唯一的userid，即所谓“帐号”。在企业微信管理后台->“通讯录”->点进某个成员的详情页，可以看到。",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "updatepsw":
          // 修改密码
          this.tempEmployee = data;
          this.dialogData.width = "30%";
          this.dialogData.title = "员工密码修改";
          this.dialogData.empolyeeId = data.userId;
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "update_employee_psw",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          role = [];
          company = [];

          this.dialogData.data = [
            // {
            //   title: "原密码",
            //   type: "text",
            //   value: data.passWord,
            // },
            {
              title: "新密码",
              type: "input",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "settingPhone":
          // 呼叫设置
          this.settingPhone();
          break;
        default:
          break;
      }
    },
    /**
     * dialog事件
     */
    drawerOper(active) {
      let role = [];
      let company = [];
      let new_company = [];
      let requestData = {};
      switch (active) {
        case "add_employee":
          console.log("确定创建:", this.dialogData);
          if (this.dialogData.data[0].value == "") {
            this.$message.error("员工姓名不能为空!");
            return;
          }
          if (this.dialogData.data[1].locationId == "") {
            this.$message.error("员工部门不能为空!");
            return;
          }
          if (this.dialogData.data[3].value == "") {
            this.$message.error("员工手机号不能为空!");
            return;
          }
          if (this.dialogData.data[4].value == "") {
            this.$message.error("员工入职时间不能为空!");
            return;
          }
          if (this.dialogData.data[8].value.value == "") {
            this.$message.error("合同附件不能为空!");
            return;
          }
          if (this.dialogData.data[10].value == "") {
            this.$message.error("员工合同期限不能为空!");
            return;
          }
          if (this.dialogData.data[11].value == "") {
            this.$message.error("员工基本工资不能为空!");
            return;
          }
          requestData = {
            userName: this.dialogData.data[0].value, // 员工姓名
            departmentId: this.dialogData.data[1].locationId, // 员工部门
            position: this.dialogData.data[2].value, // 员工部门
            phone: this.dialogData.data[3].value, // 手机号
            psw: "111111", // 默认初始密码
            icon: this.$baseimgurl, // 默认图像
            hiredate: this.dialogData.data[4].value, // 入职时间,
            identity: this.dialogData.data[5].value, // 身份证号
            state: this.dialogData.data[6].value, // 状态:1-> 在职员工(默认状态);0->离职员工
            education: this.dialogData.data[7].value, // 学历
            file: this.dialogData.data[8].value.value, // 合同附件
            type: this.dialogData.data[9].value, // 合同类型
            time: this.dialogData.data[10].value, // 合同期限
            basicSalary: this.dialogData.data[11].value, // 基本工资
            companyId: this.baseInfo.company.companyId, // 公司ID
          };
          this.$request(
            "post",
            "/user/createEmployee",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getEmployeeListByCompany();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "update_employee":
          if (this.dialogData.data[0].value == "") {
            this.$message.error("员工姓名不能为空!");
            return;
          }
          if (this.dialogData.data[2].value == "") {
            this.$message.error("员工手机号不能为空!");
            return;
          }
          if (this.dialogData.data[3].value == "") {
            this.$message.error("员工入职时间不能为空!");
            return;
          }
          let flag1 = this.$phoneCheck.mobilePhoneNumberCheck(
            this.dialogData.data[2].value
          );
          if (!flag1) {
            this.$message.error("手机号格式不正确,请确认填入正确的手机号!");
            return;
          }
          requestData = {
            userId: this.dialogData.empolyeeId,
            companyId: this.baseInfo.company.companyId, // 公司ID
            userName: this.dialogData.data[0].value, // 员工名
            position: this.dialogData.data[1].value, // 职位
            phone: this.dialogData.data[2].value, // 手机号
            psw: "111111", // 默认初始密码
            icon: this.$baseimgurl, // 默认图像
            hiredate: this.dialogData.data[3].value, // 入职时间,
            identity: this.dialogData.data[4].value, // 身份证号
            state: this.dialogData.data[5].value, // 状态:1-> 在职员工(默认状态);0->离职员工
            education: this.dialogData.data[6].value, // 学历
          };
          this.$request(
            "post",
            "/user/updateEmployee",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getEmployeeListByCompany();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );

          break;
        case "update_employee_wechat":
          // 更新企业微信
          if (this.dialogData.data[1].value == "") {
            this.$message.error("员工企业微信账号不能为空!");
            return;
          }
          requestData = {
            userId: this.dialogData.empolyeeId,
            wechatUserId: this.dialogData.data[1].value, // 企业微信账号
          };
          this.$request(
            "post",
            "/user/updateEmployeeWechatUserId",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "企业微信绑定成功!",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getEmployeeListByCompany();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "update_employee_psw":
          // 确认修改密码
          if (this.dialogData.data[0].value == "") {
            this.$message.error("新密码不能为空!");
            return;
          }
          requestData = {
            userId: this.dialogData.empolyeeId,
            oldPsw: this.tempEmployee.passWord, // 原密码
            newPsw: this.dialogData.data[0].value, // 新密码
          };
          this.$request(
            "post",
            "/user/updateEmployeePsw",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "密码修改成功",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getEmployeeListByCompany();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "departure-transfer":
          // 离职并交接
          this.dialogData.width = "30%";
          this.dialogData.title = "离职交接";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "departure_transfer",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          let options = [];
          this.empolyeeList.forEach((element) => {
            let dd = {
              value: element.userId,
              label: element.userName,
            };
            options.push(dd);
          });
          this.dialogData.data = [
            {
              title: "交接员工",
              type: "select-search",
              value: "",
              options: options,
            },
          ];
          console.log("ssssssss", options);
          this.dialogData.flag = true;
          break;
        case "departure_transfer":
          requestData = {
            userId: this.tempEmployee.userId,
            handoverUserId: this.dialogData.data[0].value,
            companyId: this.baseInfo.company.companyId,
          };
          if (requestData.handoverUserId == "") {
            this.$message.error("请选择要交接的员工!");
            return;
          }
          this.$request(
            "post",
            "/user/staffQuit",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000,
              });
              this.drawerOper.flag = false;
              this.dialogData.flag = false;

              this.dialogData.title = "离职交接内容";
              this.dialogData.buttonArray = [];
              this.dialogData.buttonArray.push({
                text: "取消",
                active: "cancel",
              });
              this.dialogData.data = [];
              this.dialogData.data.push({
                title: "",
                type: "leave_content",
                value: data,
              });
              this.dialogData.flag = true;

              // this.getEmployeeListByCompany();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        // case "results_point_to_modify":
        //   //  记单修改
        //   console.log("kkkkkkkkkkkkkkkkkkkkk");
        //   this.dialogData.width = "30%";
        //   this.dialogData.title = "员工记单修改";
        //   this.dialogData.buttonArray = [
        //     {
        //       text: "确认",
        //       active: "makesure_results_point_to_modify",
        //     },
        //     {
        //       text: "取消",
        //       active: "cancel",
        //     },
        //   ];
        //   this.dialogData.data = [
        //     { title: "记单调整", type: "input", value: "" },
        //   ];
        //   this.dialogData.flag = true;
        //   break;
        // case "makesure_results_point_to_modify":
        //   //  确认记单修改
        //   requestData = {
        //     updateUserId: this.tempEmployee.userId,
        //     companyId: this.baseInfo.company.companyId, // 公司ID
        //     achievementFloat: this.dialogData.data[0].value,
        //   };
        //   this.$request(
        //     "post",
        //     "/user/updateAchievementFloat",
        //     requestData,
        //     (data) => {
        //       this.$message({
        //         type: "success",
        //         message: "记单修改成功!",
        //         duration: 1000,
        //       });
        //       this.dialogData.flag = false;
        //       this.employeeDrawerData.flag = false;
        //       this.getEmployeeListByCompany();
        //     },
        //     (errorMessage) => {
        //       this.$message.error(errorMessage);
        //       this.dialogData.flag = false;
        //     }
        //   );
        //   break;
        case "addNumber":
          // 添加号码组合
          this.dialogData.data[0].value.push({
            userId: this.tempData.userId,
            callNumber: "", // 呼叫号
            middleNumber: "", // 中间号
          });
          break;
        case "saveNumber":
          // 保存号码组合
          requestData = {
            telUsers: this.dialogData.data[0].value,
            userId: this.tempData.userId,
          };
          requestData.telUsers.forEach((item, index) => {
            item.sort = index;
          });
          this.$request(
            "post",
            "/telUser/renewal",
            requestData,
            (data) => {
              this.$message.success("保存成功!");
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "delete":
          requestData = {
            userId: this.tempEmployee.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/user/deleteEmployee",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
                duration: 1000,
              });
              this.employeeDrawerData.flag = false;
              this.getEmployeeListByCompany(0);
            },
            (err) => {
              this.$message.error(err);
            }
          );
          // 离职员工的删除
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    handleAvatarSuccess(data) {
      console.log(data);
      this.dialogData.data[8].value.value = data.result;
    },
    /**
     * dialog父子组件连锁事件
     */
    authorityShow(data) {
      if (this.dialogData.data.length > 0) {
        for (let index = 0; index < this.dialogData.data.length; index++) {
          let element = this.dialogData.data[index];
          if (element.type == "role_tree") {
            element.data = data;
          }
        }
      }
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.employeeDrawerData.flag = false;
    },
    /**
     * dialog关闭
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.employeeDrawerData.flag = false;
      this.getEmployeeListByCompany();
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    /**
     * 外呼号码设置
     */
    settingPhone() {
      this.$request(
        "get",
        "/telUser/listByUser?userId=" + this.tempData.userId,
        {},
        (data) => {
          this.dialogData.width = "650px";
          this.dialogData.title = "外呼号码设置";
          this.dialogData.buttonArray = [
            {
              text: "添加组合",
              active: "addNumber",
            },
            {
              text: "保存",
              active: "saveNumber",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "number-setting",
              value: data || [],
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 启用账号
     */
    launch(data) {
      this.$confirm(
        "此操作将禁用其他号码组合, 并启用该号码组合, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$request(
            "get",
            "/telUser/enable?id=" + data.item.id,
            {},
            (data) => {
              this.$message.success("启用成功!");
              this.settingPhone();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    /**
     * 删除账号
     */
    deleteItem(index) {
      this.dialogData.data[0].value.splice(index, 1);
    },
  },
  mounted() {
    this.loading = true;
    this.isDisable = this.$route.query.isDisable; // 是否禁用操作按钮
    console.log("路由传值", this.$route.query.isDisable);
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.getDepartmentList();
  },
};
</script>

<style lang="less" scoped>
#employee {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .employee-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }

  .employee-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .employee-screen-active {
    flex: 1;
  }
  .employee-screen-but {
    width: 120px;
    min-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    margin-right: 20px;
  }
  .employee-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .employee-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
  }
}
</style>
