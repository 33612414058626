<template>
  <div id="main">
    <div class="main-top">
      <!-- <div class="main-top-tip">{{ tip }}</div> -->
      <div class="main-top-company">
        <el-popover
          placement="bottom"
          width="250"
          trigger="hover"
          :visible-arrow="false"
        >
          <div class="main-top-company-select">
            <el-select
              v-model="company"
              value-key="companyId"
              placeholder="请选择"
              filterable
              @change="changeCompany"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div slot="reference">
            <!-- {{ company.companyName | company }} -->
            <img
              style="width: 140px; height: 40px"
              :src="logo"
              object-fit="contain"
            />
          </div>
        </el-popover>
      </div>
      <div class="main-top-navigation">
        <div
          :class="{
            'main-top-navigation-menu': true,
            'is-active': activeIndex == 'index',
          }"
          v-if="buttonAuthorityList.index < 3"
          @click="
            handleSelect({ name: '首页', active: 'index', model: '/index' })
          "
        >
          首页
        </div>
        <div
          :class="{
            'main-top-navigation-menu': true,
            'is-active': activeIndex == item.active,
          }"
          v-for="(item, index) in navList"
          v-dragging="{
            list: navList,
            item: item,
            group: 'text',
          }"
          :key="index"
          v-show="item.flag < 3"
          @click="handleSelect(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="main-top-more"></div>
      <div class="main-top-active"></div>
      <div class="main-top-message">
        <el-badge
          :hidden="UnconfirmedCount == 0"
          :value="UnconfirmedCount"
          :max="99"
          class="badge"
        >
          <img
            class="img"
            src="../assets/img/daiban.png"
            alt=""
            @click="getEmployeeList"
          />
        </el-badge>
      </div>
      <!-- <div class="main-top-message">
        <el-popover
          placement="bottom-end"
          popper-class="my-popover"
          width="200"
          trigger="click"
          :visible-arrow="false"
        >
          <div class="main-top-message-content">
            <div class="main-top-message-top">
              <div class="title">待办提醒</div>
              <div class="main-top-message-top-active"></div>
              <div class="main-top-message-top-line">
                <el-select
                  style="width: 120px;"
                  v-model="status"
                  @change="waitList"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-tooltip
                  effect="light"
                  content="全部标记已读"
                  placement="top"
                >
                  <i
                    style="font-size: 17px; margin-left: 5px; cursor: pointer;"
                    class="el-icon-document-checked"
                    @click="allRead"
                  ></i>
                </el-tooltip>
              </div>
            </div>
            <div class="main-top-message-main">
              <div
                class="main-top-message-main-line"
                v-for="item in waiteDataList"
                :key="item.index"
              >
                <div class="main-top-message-main-line-time">
                  {{ item.expectTime }}
                </div>
                <div
                  :class="{
                    main_top_message_main_line_name: true,
                    isActive: item.status == 0,
                  }"
                  @click="complete(item.type, item.status, item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="main-top-message-bottom">
              <el-pagination
                small
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageData.currentPage"
                :page-size="pageData.pageSize"
                layout="total, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>

          <div slot="reference">
            <el-badge
              :hidden="unreadLength == 0"
              :value="unreadLength"
              :max="99"
              class="badge"
            >
              <i class="iconfont icon-lingdang"></i>
            </el-badge>
          </div>
        </el-popover>
      </div> -->
      <div class="main-top-userInfo">
        <el-popover
          placement="bottom"
          width="150"
          trigger="hover"
          :visible-arrow="false"
        >
          <div class="main-top-userInfo-float">
            <div class="main-top-userInfo-float-info">
              <img
                style="width: 40px; height: 40px; borderradius: 20px"
                :src="icon"
                object-fit="cover"
              />
              <!-- <img style="width: 40px; height: 40px" :src="icon" /> -->
              <div class="main-top-userInfo-float-info-name">
                {{ baseInfo.userName }}
              </div>
            </div>
            <div class="main-top-userInfo-float-operlist">
              <div
                class="main-top-userInfo-float-oper"
                style="height: 40px; cursor: pointer"
                v-for="(item, index) in operList"
                :key="index"
                @click="sysSetting(item)"
                v-show="item.flag < 3"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
          <div slot="reference" class="main-top-userInfo-show">
            <img
              style="
                width: 40px;
                height: 40px;
                border-radius: 20px;
                margin-right: 5px;
              "
              :src="icon"
              object-fit="contain"
            />
            <i class="el-icon-arrow-down"></i>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="main-content">
      <router-view />
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawerFlag"
      :with-header="false"
      size="55%"
      @close="closeDrawer"
    >
      <div class="company-drawer">
        <div class="company-drawer-left" v-loading="leftList.loading">
          <div class="company-drawer-left-search">
            <div class="company-drawer-left-search-line">
              <el-input
                placeholder="请输入客户全称/编号/税号"
                v-model="leftList.searchData.input"
                @keyup.enter.native="openDrawer"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="openDrawer"
                ></el-button>
              </el-input>
            </div>
            <div class="company-drawer-left-search-line">
              <el-select
                v-model="leftList.searchData.userId"
                @change="selectInitTableData"
                filterable
              >
                <el-option
                  v-for="item in leftList.searchData.userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div style="flex: 1"></div>
              <div class="company-drawer-left-search-fiftrate">
                <el-popover
                  placement="bottom-start"
                  width="250"
                  trigger="manual"
                  :visible-arrow="false"
                  v-model="popoData.popoVisible"
                >
                  <div class="popover-search">
                    <popo_search
                      :list="popoData.list"
                      @cancel="popoCanael"
                      @reset="popoReset"
                      @makeSure="popoMakeSure"
                    ></popo_search>
                  </div>
                  <div slot="reference" @click="popoverOpen">
                    筛选 <i class="el-icon-s-operation"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <div
              class="company-drawer-left-search-line"
              style="flex-wrap: wrap;"
            >
              <div
                :class="{
                  'company-drawer-left-search-status': true,
                  active: index === leftList.searchData.activeFlag,
                }"
                v-for="(item, index) in tabListStatus"
                :key="item.label"
                @click="changeStatus(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
          <div
            class="company-drawer-left-list"
            v-if="leftList.tableData.length > 0"
          >
            <div
              :class="{
                'company-drawer-left-list-item': true,
                active: tempClient.id == item.id,
              }"
              v-for="item in leftList.tableData"
              :key="item.name"
              @click="clickClient(item)"
            >
              <el-badge :value="item.unconfirmedMsgCount" :max="99">
                <span class="company-drawer-left-list-item-company">{{
                  item.clientName
                }}</span>
              </el-badge>
              <span class="company-drawer-left-list-item-status">
                <span
                  class="company-drawer-left-list-item-status-item"
                  style="color: #67C23A; borderColor: #67C23A"
                  v-if="item.unfinishedTaskCount > 0"
                  >待办</span
                >
                <span
                  class="company-drawer-left-list-item-status-item"
                  style="color: #E6A23C; borderColor: #E6A23C"
                  v-if="item.notInvoicedCount > 0"
                  >发票</span
                >
                <span
                  class="company-drawer-left-list-item-status-item"
                  style="color: #409EFF; borderColor: #409EFF"
                  v-if="item.unauditedSocialSecurityCount > 0"
                  >社保</span
                >
              </span>
            </div>
          </div>
        </div>
        <div class="company-drawer-right">
          <div class="company-drawer-right-top">
            <div class="company-drawer-right-top-title">
              <div class="company-drawer-right-top-icon">
                <i class="el-icon-office-building"></i>
              </div>
              <div class="company-drawer-right-top-name">
                <div class="company-drawer-right-top-name-title">
                  {{ this.tempClient.clientName }}
                </div>
                <div class="company-drawer-right-top-name-number">
                  {{ this.tempClient.clientNum }}
                </div>
                <div class="company-drawer-right-top-copy" @click="copyClient">
                  <i class="el-icon-document-copy"></i>
                </div>
              </div>
            </div>

            <div class="company-drawer-right-top-tabs">
              <el-tabs v-model="tabActiveName">
                <el-tab-pane
                  v-for="(item, index) in tabList"
                  :key="index"
                  :label="item.label"
                  :name="item.name"
                >
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="company-drawer-right-center">
            <companyDrawerExchange
              ref="companyDrawerExchange"
              v-if="tabActiveName == '沟通记录'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerExchange>
            <companyDrawerTableAssignment
              v-if="tabActiveName == '待办'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableAssignment>
            <companyDrawerTableInvoice
              v-if="tabActiveName == '发票'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableInvoice>
            <companyDrawerTableSocial
              v-if="tabActiveName == '社保'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableSocial>
            <companyDrawerTableTax
              v-if="tabActiveName == '税收'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableTax>
            <companyDrawerTableStatement
              v-if="tabActiveName == '收入'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableStatement>
            <companyDrawerTableCost
              v-if="tabActiveName == '成本'"
              :tempClient="tempClient"
              @editClient="editClient"
            ></companyDrawerTableCost>
          </div>
        </div>
        <div class="company-drawer-button" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import _ from "lodash";
import * as WaiteData from "@/assets/js/waiteData.js";
import popo_search from "@/components/popo-search.vue";
import * as companyDrawerData from "@/assets/js/companyDrawerData.js";
import companyDrawerExchange from "./main-components/company-drawer-exchange.vue";
import companyDrawerTableInvoice from "./main-components/company-drawer-table-invoice";
import companyDrawerTableSocial from "./main-components/company-drawer-table-social";
import companyDrawerTableAssignment from "./main-components/company-drawer-table-assignment";
import companyDrawerTableCost from "./main-components/company-drawer-table-cost";
import companyDrawerTableTax from "./main-components/company-drawer-table-tax";
import companyDrawerTableStatement from "./main-components/company-drawer-statement.vue";
export default {
  components: {
    popo_search,
    companyDrawerExchange,
    companyDrawerTableInvoice,
    companyDrawerTableSocial,
    companyDrawerTableAssignment,
    companyDrawerTableCost,
    companyDrawerTableTax,
    companyDrawerTableStatement,
  },
  filters: {
    //超过18位显示 ...
    company: function(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 10) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      company: {},
      companyList: [],
      baseInfo: {},
      logo: require("../assets/img/company_logo.png"),
      icon: "",
      operList: [
        {
          text: "邀请同事",
          flag: 1,
          active: "",
        },
        {
          text: "部门及员工设置",
          flag: 1,
          active: "skip",
          path: "department",
          model: "/department",
        },
        {
          text: "个人设置",
          flag: 1,
          active: "skip",
          path: "person",
          model: "/person",
        },
        {
          text: "系统设置",
          flag: 1,
          active: "skip",
          path: "system",
          model: "/setting",
        },
        {
          text: "常用菜单配置",
          flag: 1,
          active: "",
        },
        {
          text: "意见反馈",
          flag: 1,
          active: "",
        },
        {
          text: "退出登录",
          flag: 1,
          active: "exit",
        },
      ],
      activeIndex: "index",
      tip: "商机",
      navList: [],
      buttonAuthorityList: {
        index: 1,
        thread: 1,
        contract: 1,
        client: 1,
        invoice: 1,
        product: 1,
        waite: 1,
        report: 1,
        knowledge: 1,
        human: 1,
        reimbursement: 1,
        archives: 1,
        expressage: 1,
        dispatch: 1,
        tax: 1,
        human: 1,
      },
      status: 0,
      statusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未读",
          value: 0,
        },
        {
          label: "已读",
          value: 1,
        },
      ],
      waiteStatus: "",
      pageData: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      unreadLength: "", // 未读条数
      waiteDataList: [],
      drawerFlag: false,
      popoData: {
        popoVisible: false,
        list: [],
      },
      UnconfirmedCount: 0, // 未读消息数量
      drawerData: {},
      tempClient: {},
      tabActiveName: "沟通记录",
      tabList: companyDrawerData.getCompanyDrawerTabList(),
      tabListStatus: companyDrawerData.getCompanyDrawerTabListStatus(),
      leftList: {
        loading: false,
        dom: "",
        searchData: {
          input: "", // 左上搜索框
          flag: "", // 状态
          activeFlag: "", //激活状态
          userId: "",
          tempUserId: [],
          userList: [],
          userIdList: [],
        },
        tableData: [],
        pageData: {
          // 分页参数
          currentPage: 1, // 当前页数
          total: 0, // 总条目数
          pageSize: 30, // 每页条数
        },
      },
      rightList: {
        dom: "",
        tableData: [],
        pageData: {
          // 分页参数
          currentPage: 1, // 当前页数
          total: 0, // 总条目数
          pageSize: 30, // 每页条数
        },
      },
    };
  },
  methods: {
    /**
     * 新版获取初始菜单权限
     */
    getUserAuthorityByModels() {
      console.log("this.navList", this.navList);

      this.$authority.getUserAuthorityByModels(this, this.navList, (data) => {
        data.forEach((item, index) => {
          if (item.name === "产品") {
            // 删除一级列表的产品模块
            data.splice(index, 1);
            // 将产品模块添加到系统设置
            this.operList.splice(1, 0, {
              text: "产品",
              flag: 1,
              active: "skip",
              path: "product",
              model: "/product",
            });
          }
        });
        // 获取一级模块权限;
        this.navList = data;
        console.log("一级模块权限", this.navList);
        this.activeIndex = sessionStorage.getItem("menuActiveIndex")
          ? sessionStorage.getItem("menuActiveIndex")
          : "index";

        sessionStorage.setItem("menuActiveIndex", this.activeIndex);
        if (this.activeIndex == "index") {
          this.$router.push("/main/index");
        }
      });
    },
    /**
     * 切换公司
     */
    changeCompany() {
      this.baseInfo.company = this.company;
      this.logo = this.company.logo;
      this.$store.dispatch("setCompanyLogo", this.logo);
      sessionStorage.setItem("userData", JSON.stringify(this.baseInfo));
      location.reload();
    },
    /**
     * 导航跳转
     */
    handleSelect(data) {
      //  ||
      //   (data.name == "发票" && this.buttonAuthorityList.invoice == 1)
      if (this.baseInfo.userId == "super") {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
        this.activeIndex = -1;
      } else {
        if (
          (data.name == "首页" && this.buttonAuthorityList.index == 1) ||
          (data.name == "商机" && this.buttonAuthorityList.thread == 1) ||
          (data.name == "合同" && this.buttonAuthorityList.contract == 1) ||
          (data.name == "客户" && this.buttonAuthorityList.client == 1) ||
          (data.name == "发票" && this.buttonAuthorityList.invoice == 1) ||
          (data.name == "产品" && this.buttonAuthorityList.product == 1) ||
          (data.name == "待办" && this.buttonAuthorityList.waite == 1) ||
          (data.name == "报销" &&
            this.buttonAuthorityList.reimbursement == 1) ||
          (data.name == "报表" && this.buttonAuthorityList.report == 1) ||
          (data.name == "知识" && this.buttonAuthorityList.knowledge == 1) ||
          (data.name == "人才" && this.buttonAuthorityList.human == 1) ||
          (data.name == "档案" && this.buttonAuthorityList.archives == 1) ||
          (data.name == "快递" && this.buttonAuthorityList.expressage == 1) ||
          (data.name == "人资" && this.buttonAuthorityList.expressage == 1) ||
          (data.name == "派工" && this.buttonAuthorityList.dispatch == 1) ||
          (data.name == "税务" && this.buttonAuthorityList.tax == 1)
        ) {
          this.activeIndex = data.active;
          sessionStorage.setItem("menuActiveIndex", this.activeIndex);
          this.hasPermission(data.model, data.active, false);
        } else {
          this.$message.error(
            this.$errorMessage.getErrorMessageInfo("authority")
          );
        }
      }
    },
    /**
     * 系统设置
     */
    sysSetting(data) {
      // let isDisable = this.companyList.length == 0 ? true : false;
      // if (data.flag == 1) {
      switch (data.active) {
        case "skip":
          this.activeIndex = "";
          sessionStorage.setItem("menuActiveIndex", data.path);
          this.hasPermission(data.model, data.path, false);
          break;
        case "exit":
          // 退出登录
          this.activeIndex = "";
          sessionStorage.setItem("userData", "");
          sessionStorage.setItem("menuActiveIndex", "index");
          sessionStorage.setItem("menuSecondActiveIndex", "");
          this.$router.push("/");
          break;
        default:
          break;
      }
      // } else {
      //   this.$message.error(
      //     this.$errorMessage.getErrorMessageInfo("authority")
      //   );
      // }
    },
    /**
     * 判断当前登录用户有没有进入系统设置的权限
     */
    hasPermission(model, path, isDisable) {
      console.log("路由跳转", path);
      this.$router.push("/main/" + path);
    },
    /**
     * 图片加载失败
     */
    iconerror(e) {
      console.log("图片加载失败:", e);
      e.target.src = require("../assets/img/icon.png");
    },
    waitList() {
      let requestData = {
        userId: this.baseInfo.userId,
        status: this.status, // 状态：0-未读（未完成） 1-已读（已完成）。查询全部则该字段不传或传入空字符串
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 一页大小
      };
      this.$request(
        "post",
        "/waitDeal/listByType",
        requestData,
        (data) => {
          this.waiteDataList = data.records;
          this.pageData.total = data.total;
          if (this.status === 0) {
            this.unreadLength = data.total;
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 点击已读 跳转对应界面
     */
    complete(type, status, id) {
      let requestData = {
        userId: this.baseInfo.userId,
        id: id, // 派工 id
      };
      if (status == 0) {
        this.$request(
          "post",
          "/waitDeal/complete",
          requestData,
          (data) => {
            this.$router.push(WaiteData.routingHop(type, 1));
            this.waitList();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 全部已读
     */
    allRead() {
      let requestData = {
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/waitDeal/readAll",
        requestData,
        (data) => {
          this.waitList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.waitList();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.waitList();
    },
    /**
     * 获取未读消息数量
     */
    getUnconfirmedCount() {
      let requestData = {
        department: "",
        flag: 0,
        search: "",
        userIdList: [this.baseInfo.userId],
      };
      this.$request(
        "post",
        "/client/getUnconfirmedCount",
        requestData,
        (data) => {
          this.UnconfirmedCount = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉 打开待办抽屉
     */
    openDrawer() {
      this.leftList.pageData = {
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 30, // 每页条数
      };
      this.leftList.tableData = [];
      this.getClientList()
        .then((data) => {
          this.leftList.tableData = data.records;
          this.leftList.pageData.total = data.total;
          if (this.leftList.tableData.length > 0) {
            // 模拟打开抽屉 点击第一个用户
            this.clickClient(this.leftList.tableData[0], 0);
          }
          this.drawerFlag = true;
          this.$nextTick(() => {
            this.leftLazyLoad();
          });
        })
        .catch((message) => {
          this.$message.error(message);
        });
    },
    /**
     * 抽屉 关闭待办抽屉
     */
    closeDrawer() {
      // this.leftList.dom.removeEventListener("scroll", this.leftLazyLoad());
      this.tempClient = {};
      this.drawerFlag = false;
    },
    /**
     * 抽屉 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      this.drawerFlag = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.leftList.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.leftList.searchData.userIdList.push(element.userId);
            this.leftList.searchData.userList.push(dd);
          });
          this.leftList.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.leftList.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.leftList.searchData.tempUserId = this.leftList.searchData.userIdList;
      } else {
        this.leftList.searchData.tempUserId = [val];
      }
      this.openDrawer();
    },
    /**
     * 抽屉 获取客户列表
     */
    getClientList() {
      let p = new Promise((resolve, reject) => {
        this.leftList.loading = true;
        let requestData = {
          search: this.leftList.searchData.input,
          department: "", // 默认最高
          flag: this.leftList.searchData.flag,
          userIdList: this.leftList.searchData.tempUserId, // 员工id集合
          pageSize: this.leftList.pageData.pageSize,
          currentPage: this.leftList.pageData.currentPage,
        };
        this.$request(
          "post",
          "/client/getOptionsForAll",
          requestData,
          (data) => {
            this.leftList.loading = false;
            data.records.forEach((item) => {
              if (item.unconfirmedMsgCount == 0) {
                item.unconfirmedMsgCount = ""; // 未确认消息数量
              }
            });
            resolve(data);
          },
          (errorMessage) => {
            reject(errorMessage);
          }
        );
      });
      return p;
    },
    changeStatus(item) {
      if (this.leftList.searchData.flag === item.value) {
        this.leftList.searchData.flag = "";
        this.leftList.searchData.activeFlag = "";
        this.openDrawer();
        return;
      }
      this.leftList.searchData.flag = item.value;
      this.leftList.searchData.activeFlag = item.value;
      this.openDrawer();
    },
    /**
     * 抽屉 点击查询客户信息
     */
    clickClient(item, index) {
      this.tempClient = item;
      this.tabActiveName = "沟通记录";
    },
    /**
     * 抽屉 复制客户名称
     */
    copyClient() {
      let copyText = this.tempClient.clientName;
      this.$copyText(copyText.replace(/( |null)/g, "")).then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (err) => {
          this.$message.error("复制失败!");
        }
      );
    },
    /**
     * 抽屉 客户名称下拉加载
     */
    leftLazyLoad() {
      this.leftList.dom = document.querySelector(".company-drawer-left-list");
      this.leftList.dom.addEventListener("scroll", (v) => {
        const scrollDistance =
          this.leftList.dom.scrollHeight -
          this.leftList.dom.scrollTop -
          this.leftList.dom.clientHeight;
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance < 1) {
          if (this.leftList.tableData.length >= this.leftList.pageData.total) {
            // 到达底部 数据加载完毕
            return;
          }
          // 滑动到表格底部 可以请求接口
          this.leftList.pageData.currentPage++;
          this.getClientList()
            .then((data) => {
              this.leftList.tableData = [
                ...this.leftList.tableData,
                ...data.records,
              ];
              this.leftList.pageData.total = data.total;
            })
            .catch((message) => {
              this.$message.error(message);
            });
        }
      });
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[1].options = []; // 下单员工
      // this.popoData.list[1].options = this.searchData.allUserList.map(
      //   (item, index) => {
      //     return { label: item.userName, value: item.userId };
      //   }
      // );
      // this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      // this.initTableData();
    },
    /**
     * 客户列表待办数量
     */
    editClient(value) {
      if (value.flag == "add") {
        // 加
        switch (value.model) {
          case "record":
            this.tempClient.unconfirmedMsgCount++;
            break;
          default:
            break;
        }
      } else {
        // 减
        switch (value.model) {
          case "record":
            if (this.tempClient.unconfirmedMsgCount > 1) {
              this.tempClient.unconfirmedMsgCount--;
            } else {
              this.tempClient.unconfirmedMsgCount = "";
            }
            break;
          case "assignment":
            this.tempClient.unfinishedTaskCount--;
            break;
          case "invoice":
            this.tempClient.notInvoicedCount--;
            break;
          case "social":
            this.tempClient.unauditedSocialSecurityCount--;
            break;
          default:
            break;
        }
      }
      this.getUnconfirmedCount();
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.icon = this.$store.getters.getIcon;
    this.baseInfo.img = this.$store.getters.getIcon;
    this.logo = this.$store.getters.getCompanyLogo;
    this.navList = this.$systemBaseData.getModelList();
    console.log("登录用户基础数据", this.baseInfo, this.icon, this.logo);
    this.company = this.baseInfo.company;
    this.companyList = this.baseInfo.companyList;
    console.log("当前索引", this.activeIndex);
    // 自定义导航顺序
    this.$dragging.$on("dragged", ({ value }) => {
      console.log(value);
    });
    this.getUserAuthorityByModels();
    this.$store.dispatch("getAllProductList", this);
    this.$store.dispatch("getAllEmployeeList", this);
    this.$store.dispatch("getAllDepartmentList", this);
    this.$store.dispatch("getAllServiceTypeList", this);
    this.$store.dispatch("getAllServicesItemList", this);
    this.$store.dispatch("getAllClientByUserId", this);
    this.$store.dispatch("getAllProfessionList", this);
    this.$store.dispatch("getCompantAccountList", this);
    // this.waitList();
    this.getUnconfirmedCount();
  },
};
</script>

<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: visible;

  .main-top {
    width: 100%;
    height: 60px;
    min-width: 520px;
    position: fixed;
    display: flex;
    background-color: @topNavColor;
    color: #fff;
    // overflow: hidden;
    .main-top-company {
      width: 200px;
      min-width: 180px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      font-size: @contentFontSize;
      font-weight: 800;
    }
    .main-top-tip {
      width: 80px;
      min-width: 80px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      font-size: @contentFontSize2;
      font-weight: 800;
    }
    .main-top-navigation {
      width: auto;
      height: 60px;
      min-width: 50px;
      // margin: 5px;
      overflow: hidden;
      display: flex;
      font-size: @contentFontSize;
      .main-top-navigation-menu {
        width: 70px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        margin: 0 1px;
      }
      .main-top-navigation-menu:hover {
        cursor: pointer;
        background-color: #fff;
        color: @activeColor;
      }
      .is-active {
        background-color: #fff;
        color: @activeColor;
        box-sizing: border-box;
        border-bottom: 4px solid @activeColor;
        box-sizing: border-box;
      }
    }
    .main-top-more {
      width: 100px;
      height: 100%;
      min-width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: 0.8s;
      margin-left: 20px;
      position: relative;
      .main-top-more_ {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.8s;
        i {
          margin: 5px 5px 0 5px;
          transition: 0.8s;
          .el-icon-arrow-down,
          .el-icon-arrow-up {
            transition: 0.8s;
          }
        }
      }
      .main-top-more-active {
        z-index: 500;
        opacity: 0;
        width: 300px;
        position: absolute;
        height: 100px;
        top: 60px;
        left: 0;
        transition: 0.8s;
        padding-top: 10px;
        color: #333;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      }
    }
    .main-top-active {
      flex: 1;
      height: 100%;
    }
    .main-top-message {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      /deep/ .el-badge__content {
        border: none;
      }
      .img {
        width: 23px;
        height: 23px;
      }
    }
    .main-top-userInfo {
      width: 70px;
      height: 50px;
      min-width: 70px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .main-top-userInfo-show {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-image {
          border-radius: 25px;
          margin-right: 8px;
          border: 1px solid @boderColor;
        }
      }
    }
  }
  .main-content {
    flex: 1;
    width: 100%;
    // min-width: 1170px;
    overflow: hidden;
    // overflow-x: auto;
    padding-top: 60px;
    // -webkit-overflow-scrolling: touch;
    // white-space: nowrap;
  }
  /deep/ .el-drawer__body {
    overflow: hidden;
  }
  .company-drawer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fdfdfd;
    .company-drawer-left {
      width: 30%;
      min-width: 260px;
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border-right: 1px solid #e9ebed;
      .company-drawer-left-search {
        padding: 0 10px;
        .company-drawer-left-search-line {
          display: flex;
          margin-bottom: 10px;
          .company-drawer-left-search-fiftrate {
            width: 65px;
            min-width: 65px;
            height: 30px;
            box-sizing: border-box;
            color: @boderActiveColor;
            font-size: @contentFontSize;
            margin-left: 10px;
            display: flex;
            // color:#409eff
            align-items: center;
            justify-content: center;
            border: 1px solid @boderColor;
            border-radius: 4px;
            transition: 0.3s;
            i {
              margin: 0 4px;
            }
            &:hover {
              cursor: pointer;
              border-color: @boderActiveColor;
            }
          }
          .company-drawer-left-search-status {
            font-size: 12px;
            padding: 3px 5px;
            border: 1px solid transparent;
            cursor: pointer;
            white-space: nowrap;
          }
          .active {
            color: #40a3ff;
            border: 1px dashed #40a3ff;
            background-color: #f2f7ff;
          }
        }
      }
      .company-drawer-left-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }

        .company-drawer-left-list-item {
          width: 100%;
          padding: 13px 10px;
          box-sizing: border-box;
          font-size: 15px;
          text-align: left;
          cursor: pointer;
          .company-drawer-left-list-item-company {
            text-align: left;
          }
          .company-drawer-left-list-item-status {
            .company-drawer-left-list-item-status-item {
              display: inline-block;
              border: 1px solid;
              border-radius: 3px;
              font-weight: normal;
              font-size: 12px;
              padding: 0 2px;
              margin: 0 5px;
            }
          }
          &:hover {
            border-radius: 5px;
            background-color: #f1f1f4;
          }
          /deep/ .el-badge__content {
            border-radius: 10px;
            font-size: 9px;
            height: 15px;
            line-height: 15px;
            padding: 0 5px;
          }
          /deep/ .el-badge__content.is-fixed {
            top: -10px;
            left: -10px;
            right: auto;
            transform: none;
          }
        }
        .active {
          border-radius: 5px;
          font-weight: 700;
          background-color: #f1f1f4;
        }
      }
    }
    .company-drawer-right {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      .company-drawer-right-top {
        width: 100%;
        height: auto;
        .company-drawer-right-top-title {
          width: 100%;
          height: 35px;
          display: flex;
          align-items: center;
          .company-drawer-right-top-icon {
            font-size: 30px;
            color: #8f9396;
            margin-right: 6px;
          }
          .company-drawer-right-top-name {
            position: relative;
            font-size: 16px;
            text-align: left;
            line-height: 1.3;

            .company-drawer-right-top-name-title {
              font-weight: 700;
            }
            .company-drawer-right-top-name-number {
              font-size: 12px;
              color: #8f9396;
            }
            .company-drawer-right-top-copy {
              position: absolute;
              top: 0;
              right: -20px;
              font-size: 12px;
              color: #8f9396;
              cursor: pointer;
            }
          }
        }
        .company-drawer-right-top-tabs {
          width: 100%;
        }
      }
      .company-drawer-right-center {
        width: 100%;
        height: 100%;
        overflow-y: hidden;
      }
    }
    .company-drawer-button {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
    /deep/ .el-input-group__append,
    .el-input-group__prepend {
      padding: 0 12px;
    }
  }
}
</style>
<style lang="less">
.my-popover {
  width: 600px !important;
  height: 350px !important;
  top: 55px !important;
  .main-top-message-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 330px;

    .main-top-message-top {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .title {
        color: @baseColor;
        font-size: 16px;
      }
      .main-top-message-top-active {
        flex: 1;
      }
    }
    .main-top-message-main {
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      &:-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .main-top-message-main-line {
        display: flex;
        align-items: center;
        height: 40px;
        min-height: 40px;
        .main-top-message-main-line-time {
          margin-right: 10px;
          min-width: 140px;
        }
        .main_top_message_main_line_name {
          cursor: pointer;
        }
        .isActive {
          color: @baseColor;
        }
      }
    }
    .main-top-message-bottom {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
