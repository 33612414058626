<template>
  <div id="human-performance">
    <!-- 业绩提成表 -->
    <div class="human-performance-screen">
      <div class="human-performance-screen-line">
        <div class="human-performance-screen-search">
          <el-input
            placeholder="客户名称"
            v-model="searchData.clientName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="human-performance-screen-search">
          <el-cascader
            v-model="searchData.productIds"
            :options="searchData.productList"
            placeholder="请选择服务项目"
            @change="initTableData"
            collapse-tags
            :props="{
              multiple: true,
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            clearable
          ></el-cascader>
        </div>
        <div class="human-performance-screen-search">
          <el-date-picker
            v-model="searchData.month"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            @change="initTableData"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="human-performance-screen-search">
          <el-date-picker
            v-model="searchData.expireTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="initTableData"
            :clearable="false"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="human-performance-screen-line">
        <div class="human-performance-screen-search">
          <el-cascader
            style="width: 160px"
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
          <el-select
            style="width: 160px"
            v-model="searchData.userId"
            placeholder="请选择员工"
            @change="selectInitTableData2"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-performance-screen-search">
          <el-select
            style="width: 110px"
            v-model="searchData.roleId"
            placeholder="请选择角色"
            @change="initTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.roleList"
              :key="item.authorityId"
              :label="item.authorityName"
              :value="item.authorityId"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="searchData.roleId"
            style="width: 110px"
            v-model="searchData.roleUserId"
            placeholder="请选择员工"
            @change="selectInitTableData1"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-performance-screen-active"></div>
        <el-button
          type="primary"
          size="small"
          plain
          style="margin-right: 10px"
          @click="handleClick({}, 3)"
          v-showFunction="{
            active: 'batchEdit',
            array: allFunctionButtons,
          }"
          >批量修改</el-button
        >
      </div>
    </div>
    <div class="human-performance-table">
      <div class="human-performance-table-card">
        <div class="human-performance-table-card-tabs">
          <div
            :class="{
              'human-performance-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            新签收款报表
          </div>
          <div
            :class="{
              'human-performance-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            续签收款报表
          </div>
          <div
            :class="{
              'human-performance-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部收款报表
          </div>
          <div
            class="human-performance-table-card-active"
            v-if="whetherAgencyHead"
          >
            <div class="textArea">合计提成:</div>
            <div class="numArea">{{ allRoyalty }}</div>
          </div>
        </div>
        <div class="human-performance-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            row-key="id"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            :tree-props="{ children: 'children' }"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="selection" width="30" fixed>
            </el-table-column>
            <el-table-column type="index" width="50" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.prop"
              :fixed="index == 0"
              :label="item.title"
              :min-width="item.prop == 'clientName' ? 250 : item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div v-if="item.prop == 'royaltyRate'">
                  <el-input
                    :onkeyup="
                      (scope.row[item.prop] = scope.row[item.prop]
                        .toString()
                        .replace(/[^0-9.]/g, ''))
                    "
                    v-model="scope.row[item.prop]"
                    @change="handleClick(scope.row, 2)"
                    :disabled="editFlag"
                  >
                  </el-input>
                  <span>%</span>
                </div>
                <span v-else>
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-performance-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[10, 30, 50, 100, 500, 1000]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as humanData from "./js/humanData";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      searchData: {
        input: "",
        checked: false,
        screenButton_export: 1, // 导出
        userId: "", // 旧的部门人员
        roleUserId: "", // 新的部门人员
        // accountId: "", // 收款账号
        accountList: [], // 收款账号
        roleId: "", // 角色ID
        roleList: [],
        userList: [],
        tempUserId: [],
        roleUserList: [],
        departTreeData: [],
        department: "",
        productList: [],
        productIds: [],
        month: [
          this.$formatTime(new Date() - 31 * 24 * 3600 * 1000, "YYYY-MM"),
          this.$formatTime(new Date() - 31 * 24 * 3600 * 1000, "YYYY-MM"),
        ],
        expireTime: [], // 合同到期时间
        clientName: "", // 客户名称
      },
      tabindex: 1,

      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: humanData.getHumanPerformanceMenuData(),
      tableData: [],
      allMoney: "", // 签约总金额
      allPaymentCollect: "", // 收款总金额
      allAmountsOw: "", // 欠款总金额
      allProfits: "", // 总利润
      allCost: "", // 合同成本
      allRoyalty: "", // 合计提成
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanPerformance"
      ),
      tempData: {},
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      editFlag: true,
    };
  },

  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "humanPerformance",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          console.log("当前显示的按钮权限", data);
          this.getEmployeeListByUserId();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.tempUserId = this.tempAllEmpolyeeList;
          this.searchData.roleUserList = this.tempAllEmpolyeeList;
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部人员",
          });
          this.initTableData();
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;

          this.getAccountList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.searchData.accountList = [];
          let dd = {};
          data.forEach((element) => {
            let account = "";
            element.accountList.forEach((ele) => {
              account =
                account == "" ? ele.account : account + "," + ele.account;
            });

            dd = {
              owner: element.owner,
              account: account,
            };
            this.searchData.accountList.push(dd);
          });

          console.log("获取所有收款账号:", this.searchData.accountList);
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    initTableData() {
      this.loading = true;
      let url =
        this.tabindex == 0
          ? "/report/getAllPaymentReport"
          : this.tabindex == 1
          ? "/report/getAllNotePaymentReport"
          : "/report/getAllRenewPaymentReport";
      let requestData = {
        searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
        productIds: this.searchData.productIds, // 产品
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        // accountId: this.searchData.accountId,
        deUser: this.searchData.tempUserId, // 旧的部门人员
        roleUser: this.searchData.roleUserList, // 新的部门人员
        role: this.searchData.roleId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        companyId: this.baseInfo.company.companyId,
        clientName: this.searchData.clientName, // 客户名称
        expireTime: this.searchData.expireTime, // 客户名称
        receivedFlag: ["1"], // 1-全部收款 2-部分收款 3-未收款
        isRoyalty: true,
      };
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.allMoney = data.allMoney;
          this.allCost = data.allCost;
          this.allPaymentCollect = data.allPaymentCollect;
          this.allAmountsOw = data.allAmountsOw;
          this.allProfits = data.allProfits;
          this.allRoyalty = data.allRoyalty;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, index) {
      this.tempData = JSON.parse(JSON.stringify(data));
      let requestData = {};
      switch (index) {
        case 1:
          // 导出收款报表
          let receivedFlag = [];
          // if (this.searchData.receivedFlag.length == 0) {
          //   receivedFlag = ["1", "2", "3"];
          // } else {
          //   for (
          //     let index = 0;
          //     index < this.searchData.receivedFlag.length;
          //     index++
          //   ) {
          //     const element = this.searchData.receivedFlag[index];
          //     switch (element) {
          //       case "全部收款":
          //         receivedFlag.push("1");
          //         break;
          //       case "部分收款":
          //         receivedFlag.push("2");
          //         break;
          //       case "未收款":
          //         receivedFlag.push("3");
          //         break;
          //       default:
          //         break;
          //     }
          //   }
          // }
          requestData = {
            searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
            productId:
              this.searchData.productId.length > 0
                ? this.searchData.productId[
                    this.searchData.productId.length - 1
                  ]
                : "", // 产品
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "",
            // accountId: this.searchData.accountId,
            deUser: this.searchData.tempUserId, // 旧的部门人员
            roleUser: this.searchData.roleUserList, // 新的部门人员
            type: this.tabindex == 0 ? 1 : this.tabindex == 1 ? 2 : 3,
            role: this.searchData.roleId,
            companyId: this.baseInfo.company.companyId,
            clientName: this.searchData.clientName, // 客户名称
            // voucherNo: this.searchData.voucherNo, // 交易单号
            receivedFlag: ["1"], // 1-全部收款 2-部分收款 3-未收款
          };
          window.location.href =
            this.$baseurl +
            "/report/exportPaymentReport?data=" +
            JSON.stringify(requestData);
          break;
        case 2:
          requestData = {
            ids: [this.tempData.contractId], // 合同 id
            royaltyRate: this.tempData.royaltyRate / 100, // 提成比率
          };
          this.$request(
            "post",
            "/contract/updateRoyaltyRate",
            requestData,
            (data) => {
              this.$message.success("修改成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 3:
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择要修改的数据!");
            return;
          }
          this.dialogData.width = "500px";
          this.dialogData.title = "提成比例修改";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "提成比例(%)",
              type: "input-number2",
              value: "",
            },
          ]),
            console.log("提成比例修改dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;

          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_edit":
          requestData = {
            ids: [], // 合同 id
            royaltyRate: this.dialogData.data[0].value / 100, // 提成比率
          };
          requestData.ids = this.multipleSelection.map((item) => item.id);
          this.$request(
            "post",
            "/contract/updateRoyaltyRate",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message.success("修改成功");
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
        default:
          break;
      }
    },
    selectInitTableData1(val) {
      if (val == "all") {
        this.searchData.roleUserList = this.tempAllEmpolyeeList;
      } else {
        this.searchData.roleUserList = [val];
      }
      this.initTableData();
    },
    selectInitTableData2(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    getTableChildrenStyle(data) {
      let row = data.row;
      let style = { height: "65px", backgroundColor: "#ffffff" };
      if (row.onlyChildrenHave) {
        style.backgroundColor = "#ebeef5";
      }
      return style;
    },
    /**
     * 表格多选事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 2) {
          sums[index] = "合计";
          return;
        }
        if (
          index == 0 ||
          index == 1 ||
          index == 3 ||
          index == 4 ||
          index == 10
        ) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.searchData.roleUserId = "all";
    // this.searchData.tempUserId = ["all"];
    this.getModelFeatureAuthority();
    this.isCharge();
  },
};
</script>

<style lang="less" scoped>
#human-performance {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .human-performance-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    flex-direction: column;

    .human-performance-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      .el-checkbox-group {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .human-performance-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }

      .human-performance-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }

      .human-performance-screen-search-left {
        margin-left: 0;
      }
      .human-performance-screen-active {
        flex: 1;
      }
      .human-performance-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .human-performance-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .human-performance-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .human-performance-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .human-performance-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-performance-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .human-performance-screen-but-boder {
        padding: 0 15px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-performance-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .human-performance-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .human-performance-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-performance-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-performance-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-performance-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .human-performance-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .human-performance-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .human-performance-table-card-content {
        flex: 1;
        width: 98%;
        .human-performance-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
        /deep/ .el-input__inner {
          width: 25px;
          padding: 0;
          margin: 0;
          color: #333;
          cursor: text;
          border: 1px solid transparent;
          background-color: transparent;
          text-align: right;
        }

        /deep/ .el-input {
          width: 70%;
        }
      }
      .human-performance-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input__inner {
    width: 300px !important;
  }
  .el-table__row--level-1 {
    background-color: #dddddd !important;
  }
}
</style>
