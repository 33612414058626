<template>
  <div>
    <el-descriptions title="用户信息">
      <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
      <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
      <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
      <el-descriptions-item label="备注">
        <el-tag size="small">学校</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="联系地址"
        >江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item
      >
    </el-descriptions>
    <div @click="ccc">点我跳转</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    ccc() {
      let a = document.createElement("a");
      (a.href =
        "data:text/plain;charset=utf-8," +
        "http://numberdesktop.siruihang.com/staticFile/%E8%90%A5%E9%94%80%E7%9F%AD%E4%BF%A1%E6%89%B9%E9%87%8F%E5%8F%91%E9%80%81%E6%A8%A1%E7%89%88.txt"),
        "_blank";
      a.download = "测试文本";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style>
.text-wrapper {
  white-space: pre-wrap;
}
</style>
