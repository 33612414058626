export function addKnowledgeDialogData(categoryList, departTreeData) {
    // console.log('categoryList', categoryList)
    let data = [{
        type: "knowledge",
        value: {
            categoryList: categoryList,
            departTreeData: departTreeData,
            departmentIds: [], // 部门
            categoryId: '', // 分组类型
            title: "", // 标题
            content: "", // 摘要
            additional: "", // 具体内容
            type: "0", //  类型
            isDisable: false,
            // textAdditional: "", // 文字
            // imgAdditional: [], // 图片
            // documentAdditional: {
            //     title: "",
            //     type: "",
            //     url: ""
            // }, // 文档
            // linkAdditional: "", // 链接
        }
    }, ]

    return data
}

export function addNoticeDialogData() {
    let data = [{
        title: "标题",
        type: "input",
        value: "",
        placeholder: "请输入公告标题",
        key: "title"
    }, {
        title: "内容",
        type: "textarea",
        placeholder: "请输入公告具体内容",
        value: "",
        key: "content"
    }, ]
    return data
}